import React, { useState } from 'react';
import styles from './CustomSolutionsDesktop.module.css';
import IndividualTutorActive from "../IndividualTutorActive.svg";
import IndividualTutorInactive from "../IndividualTutorInactive.svg";
import PreSessionInvoicing from "../PreSessionInvoicing.svg";
import AutomatedReminders from "../AutomatedReminders.svg";
import WhitelabeledInvoice from "../WhitelabeledInvoice.svg";
import TutorsActive from "../TutorsActive.svg";
import TutorsInactive from "../TutorsInactive.svg";
import RobustAnalytics from "../RobustAnalytics.svg";
import MultipleInvoice from "../MultipleInvoice.svg";
import TransactionFee from "../TransactionFee.svg";
import CoachesInactive from "../CoachesInactive.svg";
import CoachesActive from "../CoachesActive.svg";
import FlexiblePayment from "../FlexiblePayment.svg";
import CreditSystem from "../CreditSystem.svg";
import CustomBranding from "../CustomBranding.svg";

const CardDetails = ({cardData}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };

      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    return (
        <div className={styles.cardData} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {
                isHovered ? 
                (
                    <div className={styles.cardHovered}>
                      <div className={styles.cardHoverHeading}>
                        {cardData.heading}
                      </div>  
                      <div className={styles.cardHoverDescription}>
                        {cardData.description}
                      </div>
                    </div>
                )
                :
                (
                    <div className={styles.cardNormal}>
                      <img className={styles.cardImage} src={cardData.image} />
                      <div className={styles.cardHeading}>
                        {cardData.heading}
                      </div>
                    </div>
                )
            }
        </div>
    );
};

const ButtonComponent = ({ data, idx, activeIdx, setActiveIdx }) => {
    return (
        <div
            className={`${styles.buttonComponent} ${idx === activeIdx ? styles.activeButton : ''}`}
            onClick={() => {
                setActiveIdx(idx);
            }}
        >
            <img
                className={styles.buttonImage}
                src={idx === activeIdx ? data?.srcActive : data?.srcInactive}
                alt={data?.heading}
            />
            <div className={styles.buttonText}>{data?.heading}</div>
        </div>
    );
};

const CustomSolutionsDesktop = () => {
    const [activeIdx, setActiveIdx] = useState(0);
    const cardsInfo = [
        {
            heading: "Tutoring Companies",
            srcActive: TutorsActive,
            srcInactive: TutorsInactive,
            cards: [
                {
                    heading: "2.9% Flat Transaction Fees",
                    description: "Keep more of your hard-earned revenue—no hidden charges.",
                    image: TransactionFee,
                },
                {
                    heading: "Robust Analytics",
                    description: "Track performance, client behavior, and income trends with ease.",
                    image: RobustAnalytics,
                },
                {
                    heading: "Multiple Invoice Types",
                    description: "Choose from manual, automated, or subscription models to fit your business.",
                    image: MultipleInvoice,
                },
            ],
        },
        {
            heading: "Individual Tutors",
            srcActive: IndividualTutorActive,
            srcInactive: IndividualTutorInactive,
            toggle: true,
            cards: [
                {
                    heading: "Pre-Session Invoicing",
                    description: "Secure payment before lessons start, reducing no-shows.",
                    image: PreSessionInvoicing,
                },
                {
                    heading: "Automated Reminders",
                    description: "Secure payment before lessons start, reducing no-shows.",
                    image: AutomatedReminders,
                },
                {
                    heading: "White-Labeled Invoices",
                    description: "Secure payment before lessons start, reducing no-shows.",
                    image: WhitelabeledInvoice,
                },
            ],
        },
        {
            heading: "Coaches & Consultants",
            srcActive: CoachesActive,
            srcInactive: CoachesInactive,
            cards: [
                {
                    heading: "Flexible Payment Schedules",
                    description: "Set recurring or milestone-based payments to suit your services.",
                    image: FlexiblePayment,
                },
                {
                    heading: "Credit System for Loyalty",
                    description: "Offer credits for referrals or future sessions, rewarding long-term clients.",
                    image: CreditSystem,
                },
                {
                    heading: "Custom Branding",
                    description: "Align invoices with your personal brand, reinforcing trust and credibility.",
                    image: CustomBranding,
                },
            ],
        },
    ];

    return (
        <div className={styles.desktopComponent}>
            <div className={styles.textInfo}>
                <div className={styles.heading}>Custom Solutions for All</div>
                <div className={styles.description}>
                    Tailored to fit your unique tutoring needs, our solutions adapt to every challenge. Empowering tutors, students, and administrators alike!
                </div>
            </div>
            <div className={styles.buttonsArea}>
                {cardsInfo.map((card, idx) => (
                    <ButtonComponent
                        key={idx}
                        data={card}
                        idx={idx}
                        activeIdx={activeIdx}
                        setActiveIdx={setActiveIdx}
                    />
                ))}
            </div>
            <div className={styles.cardsComponent}>
               { cardsInfo[activeIdx].cards.map((cardData, idx) => <CardDetails cardData={cardData} key={idx} />)}
            </div>
            <div>

            </div>
        </div>
    );
};

export default CustomSolutionsDesktop;
