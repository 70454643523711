/*
*/

const PROD_NAME_BRAND_MAPPING = {
	"calendar" : "Calendar",
	"invoicing" : "Invoicing",
	"assignments" : "Prepwork"
}

const STRIPE_PROD_MAPPING  = {
	"calv3" : "calendar",
	"asnv3" : "assignments",
	"invv3" : "invoicing"
}

const PROD_STRIPE_MAPPING = {
	"calendar" :  "calv3" ,
	"assignments"  : "asnv3"  ,
	"invoicing" : "invv3" 
}
const KEY_PERIOD_MAPPING = {
	A : "annual",
	Q : "quarterly"
}

function handleTSChange(newTeamSizeTier,subscriptionInfo,productPricesV3){
	let tempSubInfo = structuredClone(subscriptionInfo)
	if(newTeamSizeTier === "B4") return subscriptionInfo;
	subscriptionInfo.forEach((prod,idx)=>{
		let [stripeProdName,planDuration,businessTier,prodTier] = prod.lookup_key.split("_")
		businessTier = newTeamSizeTier
		tempSubInfo[idx] = productPricesV3[STRIPE_PROD_MAPPING[stripeProdName.toLowerCase()]][KEY_PERIOD_MAPPING[planDuration]][businessTier][prodTier]
	})
	return tempSubInfo
}

export {
	handleTSChange,
	STRIPE_PROD_MAPPING,
	PROD_STRIPE_MAPPING,
	KEY_PERIOD_MAPPING,
	PROD_NAME_BRAND_MAPPING 
}
