import React from 'react'
import styles from './InvoicingFeaturesCard.module.css'

function InvoicingFeaturesCard({ summary, setActivePopup,  setLeadFormTrigger }) {
  return (
           <div className={styles.calendarFeatureCard}>
               <div>{summary}</div>
               <button onClick={() => setLeadFormTrigger(true)} > <span>Book Demo</span> </button>
           </div>
  )
}

export default InvoicingFeaturesCard