import React from 'react';
import styles from './BSMobile.module.css';
import Carousel from './Carousel';

const BusinessStatsmobile = ({setLeadFormTrigger}) => {
  return (
    <div className={styles.statisticsSection}>
      <div className={styles.statisticsCards}>
        <div className={styles.statisticsCard}>
          <div className={styles.statisticsNumber}>1000+</div>
          <div className={styles.statisticsLabel}>GLOBAL PROVIDER</div>
        </div>
        <div className={styles.statisticsCard}>
          <div className={styles.statisticsNumber}>200+</div>
          <div className={styles.statisticsLabel}>BUSINESSES ONBOARDED</div>
        </div>
      </div>
      
      <div className={styles.ctaCard}>
        <div className={styles.dots}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
        <h2 className={styles.ctaTitle}>Discover Seamless Scheduling with Evallo</h2>
        <button className={styles.ctaButton} onClick={() => setLeadFormTrigger(true)}>
          Book Demo
          <span className={styles.arrow}></span>
        </button>
      </div>
      <Carousel />
    </div>
  );
};

export default BusinessStatsmobile;

