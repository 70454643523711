import React from "react";

const InvoicingHeadIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none">
      <path
        d="M1.06583 16.3254L1.07242 16.5232C1.07829 16.699 1.08122 16.787 1.09275 16.8746L1.09559 16.8953C1.10813 16.9828 1.12891 17.0677 1.17046 17.2376C1.20914 17.3957 1.24512 17.5417 1.27861 17.6763C1.45649 18.3752 1.5878 18.8839 1.40086 18.1597C1.36423 18.0177 1.32366 17.8573 1.27861 17.6763C1.09079 16.9383 0.851065 15.9884 0.879251 16.054C0.917858 16.1438 0.982338 16.2355 1.06583 16.3254L0.722656 6.03012C0.722656 5.30688 1.17731 4.66172 1.85842 4.41847L9.38931 1.72886C11.5927 0.941942 12.6944 0.548481 13.4622 1.08962C14.2301 1.63075 14.2301 2.80059 14.2301 5.14028V10.1036C14.2301 11.2852 14.2301 11.8761 13.9213 12.3347C13.6125 12.7934 13.065 13.0156 11.9702 13.46L2.04928 17.4873C1.35749 17.7641 3.08697 17.0723 2.95153 17.1036C2.45447 17.2186 1.50141 16.7948 1.06583 16.3254Z"
        fill="#3389E4"
      />
      <path
        d="M8.80397 21.3153V16.4017V13.9627C8.80397 12.5848 7.14238 11.8898 6.16132 12.8574L1.45308 17.5012C0.722448 18.2218 0.879537 19.4404 1.76903 19.9521L6.47728 22.661C7.51226 23.2565 8.80397 22.5094 8.80397 21.3153Z"
        fill="#8BBDF2"
      />
      <path
        d="M2.17776 6.39107C1.01997 6.10162 1.45414 5.06444 1.81595 4.58203C0.85114 5.16092 0.690321 5.86846 0.730513 6.14986V17.7277C0.604331 19.6573 1.52892 19.7377 1.81033 20.0191C0.966118 18.2101 1.65515 18.0493 1.81595 17.8483L8.80527 15.0744V10.3709C8.80527 9.0202 7.32344 8.1197 6.64003 7.8383C5.63501 7.47649 3.33554 6.68051 2.17776 6.39107Z"
        fill="#1F4C7D"
      />
    </svg>
  );
};

export default InvoicingHeadIcon;
