export default function ThreeDotIcon(){
	return(
		<svg width="17" height="4" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="1.955" cy="2.00008" r="1.505" transform="rotate(-90 1.955 2.00008)" stroke="#303956" stroke-width="0.9"/>
			<circle cx="8.5009" cy="2.00008" r="1.505" transform="rotate(-90 8.5009 2.00008)" stroke="#303956" stroke-width="0.9"/>
			<circle cx="15.0458" cy="2.00008" r="1.505" transform="rotate(-90 15.0458 2.00008)" stroke="#303956" stroke-width="0.9"/>
		</svg>

	)
}
