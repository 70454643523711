import React, { useEffect, useMemo, useState } from "react"
import styles from "./pricingV2.module.css"
import SelectAccountCard from "../../components/SelectAccountCard/SelectAccountCard"
import { fetchPricesV3 } from "../../Utils/utils"
import SizeDuration from "../../components/PricingComponets/SizeDuration/SizeDuration"
import SelectProduct from "../../components/PricingComponets/SelectProduct/SelectProduct"
import PlanCard from "../../components/PricingComponets/PlanCard/PlanCard"
import CardSubHeader from "../../components/PricingComponets/CardSubHeader/CardSubHeader"
import FeaturesTable from "../../components/PricingComponets/FeatureTable/FeatureTable"
import { ASN_DATA, CALENDAR_DATA, getModifiedUserData, INV_DATA, WL_DATA } from "./helpers/featuresTableData"
import { PROD_NAME_BRAND_MAPPING } from "./helpers/utils"
import BookDemo from "../../components/BookDemo/BookDemo"

const productDataMapping = {
  calendar: CALENDAR_DATA,
  assignments: ASN_DATA,
  invoicing: INV_DATA,
}

const TIER_SIZEMAPPING = {
  B0: "2-5",
  B1: "6-10",
  B2: "11-20",
  B3: "21-50",
}

const TeamSizeSelect = ({ teamSizeTierSelected, setTeamSizeTierSelected, setStep }) => {
  return (
    <div className={styles.teamSelectContainer}>
      <div className={styles.teamSelectHeading}>Please Select your team size</div>
      <div className={styles.teamSelectorContainer}>
        <div style={{ paddingRight: "12px", width: "100%", background: "#f6f9fa", borderRadius: "6px" }}>
          <select
            style={{ color: teamSizeTierSelected === "" ? "#7C859C" : "#255355" }}
            className={styles.teamSelector}
            onChange={(e) => {
              setTeamSizeTierSelected(e.target.value)
            }}
          >
            <option value="" disabled selected>
              Select
            </option>
            {Object.entries(TIER_SIZEMAPPING).map(([tier, size]) => (
              <option value={tier} key={tier}>
                {size} Tutors
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={teamSizeTierSelected === ""}
          style={{ background: teamSizeTierSelected === "" ? "rgb(6, 113, 224, 0.2)" : "rgb(6, 113, 224, 1)" }}
          className={styles.teamSelectContinueButton}
          onClick={() => {
            setStep((prev) => prev + 1)
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

const ShowPricing = ({ teamSizeTierSelected, setTeamSizeTierSelected, productPricesV3 }) => {
  const [chosenProduct, setChosenProduct] = useState("calendar")
  const [selectedPlanType, setSelectedPlanType] = useState("annual")

  const requiredPrices = useMemo(() => {
    if (Object.keys(productPricesV3).length === 0) return
    return productPricesV3 ? productPricesV3[chosenProduct][selectedPlanType][teamSizeTierSelected] : null
  }, [teamSizeTierSelected, selectedPlanType, chosenProduct, productPricesV3, chosenProduct])

  const requiredPricesContrasting = useMemo(() => {
    if (Object.keys(productPricesV3).length === 0) return
    let tempSelectedPlanType = selectedPlanType === "quarterly" ? "annual" : "quarterly"
    return productPricesV3 ? productPricesV3[chosenProduct][tempSelectedPlanType][teamSizeTierSelected] : null
  }, [teamSizeTierSelected, selectedPlanType, chosenProduct, productPricesV3, chosenProduct])

  return (
    <>
      <div>
        <SelectProduct {...{ chosenProduct, setChosenProduct }} />
        <SizeDuration {...{ selectedPlanType, setSelectedPlanType, teamSizeTierSelected, setTeamSizeTierSelected, isIndividual: teamSizeTierSelected?.includes("I") }} />
        <PlanCard {...{ chosenProduct, requiredPrices, requiredPricesContrasting, selectedPlanType, teamSizeTierSelected, isIndividual: teamSizeTierSelected?.includes("I"), productPricesV3 }} />
      </div>
      <hr className={styles.divider} />
      <div className={styles.featureContainer}>
        <CardSubHeader />
        <FeaturesTable data={productDataMapping[chosenProduct]} header={PROD_NAME_BRAND_MAPPING[chosenProduct]} requiredPrices={requiredPrices} teamSizeTierSelected={teamSizeTierSelected} />
        <hr className={styles.dottedDividerDesktop} />
        <div className={styles.dottedDividerMobile}>
          <div>User Management</div>
        </div>
        <FeaturesTable data={getModifiedUserData(teamSizeTierSelected)} header={"User Management"} requiredPrices={null} renderOnNull={true} teamSizeTierSelected={teamSizeTierSelected} />
        <hr className={styles.dottedDividerDesktop} />
        <div className={styles.dottedDividerMobile}>
          <div>White Labelling</div>
        </div>
        <FeaturesTable data={WL_DATA} header={"White Labeling"} requiredPrices={null} renderOnNull={true} teamSizeTierSelected={teamSizeTierSelected} />
      </div>
    </>
  )
}

const PricingV2 = () => {
  const [productPricesV3, setProductPricesV3] = useState({})
  const [teamSizeTierSelected, setTeamSizeTierSelected] = useState(null)
  const [step, setStep] = useState(1)
  useEffect(() => {
    const getPrices = async () => {
      const data = await fetchPricesV3()
      setProductPricesV3(data)
    }
    getPrices()
  }, [])

  return (
    <>
      <div className={styles.container}>{step === 1 ? <SelectAccountCard {...{ setTeamSizeTierSelected, setStep }} /> : step === 2 ? <TeamSizeSelect {...{ teamSizeTierSelected, setTeamSizeTierSelected, setStep }} /> : <ShowPricing {...{ teamSizeTierSelected, productPricesV3, setTeamSizeTierSelected }} />}</div>
      <div className={styles.demoDiv}>
        <BookDemo />
      </div>
    </>
  )
}

export default PricingV2
