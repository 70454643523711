const HorizontalDivider = (props) => {
  const { customClasses, ...rest } = props
  return (
    <div
      style={{
        width: "100%",
        height: "1px",
        borderTop: "1px",
        backgroundColor: '#E9EBEF'
      }}
      className={`w-full h-[1px] border-t-[1px] ${customClasses || ""}`}
      {...rest}
    />
  )
}

export default HorizontalDivider
