export default function ArmadaMbIcon(props){
	return(
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_43361_46123)">
				<g filter="url(#filter0_i_43361_46123)">
					<rect x="0.888672" width="16" height="16" rx="2.7234" fill="white"/>
				</g>
				<g opacity="0.48" filter="url(#filter1_f_43361_46123)">
					<path d="M4.24488 7.83919L5.33119 10.5536C6.42627 10.3468 7.55865 10.237 8.71855 10.237C9.87825 10.237 11.0106 10.3468 12.1057 10.5536L13.192 7.83919C13.3398 7.86413 13.4962 7.80812 13.5863 7.68381C13.7086 7.51501 13.6621 7.2852 13.4831 7.17029C13.3037 7.05519 13.0597 7.09874 12.9374 7.26755C12.8473 7.39166 12.8489 7.54915 12.9276 7.66943L11.8613 8.57505C11.5404 8.84763 11.0296 8.6161 11.0559 8.21L11.1433 6.86107C11.2921 6.84399 11.4238 6.74712 11.4709 6.60325C11.5351 6.40816 11.4187 6.20118 11.2116 6.14114C11.0045 6.08071 10.7846 6.19024 10.7203 6.38533C10.6733 6.52901 10.724 6.67883 10.8367 6.77206L10.1017 7.933C9.88029 8.28251 9.3195 8.20309 9.21737 7.80773L8.87878 6.49429C9.01556 6.43674 9.11116 6.30726 9.11116 6.15706C9.11116 5.95276 8.93545 5.7876 8.71835 5.7876C8.50145 5.7876 8.32553 5.95295 8.32553 6.15706C8.32553 6.30726 8.42114 6.43674 8.55812 6.49429L8.21933 7.80773C8.1174 8.20309 7.55641 8.28251 7.33503 7.933L6.60015 6.77206C6.71288 6.67883 6.76364 6.52901 6.71655 6.38533C6.65295 6.19024 6.43279 6.08071 6.22548 6.14114C6.01816 6.20099 5.90177 6.40816 5.96598 6.60325C6.01307 6.74712 6.14496 6.84399 6.29356 6.86107L6.38122 8.21019C6.40752 8.61629 5.89667 8.84782 5.57561 8.57524L4.50927 7.66962C4.58816 7.54953 4.58979 7.39204 4.4999 7.26774C4.37759 7.09912 4.13317 7.05558 3.95379 7.17048C3.7746 7.28539 3.72833 7.5152 3.85043 7.68401C3.94094 7.80812 4.0973 7.86432 4.24488 7.83919Z" fill="#4092E6"/>
				</g>
				<path d="M4.24488 7.83919L5.33119 10.5536C6.42627 10.3468 7.55865 10.237 8.71855 10.237C9.87825 10.237 11.0106 10.3468 12.1057 10.5536L13.192 7.83919C13.3398 7.86413 13.4962 7.80812 13.5863 7.68381C13.7086 7.51501 13.6621 7.2852 13.4831 7.17029C13.3037 7.05519 13.0597 7.09874 12.9374 7.26755C12.8473 7.39166 12.8489 7.54915 12.9276 7.66943L11.8613 8.57505C11.5404 8.84763 11.0296 8.6161 11.0559 8.21L11.1433 6.86107C11.2921 6.84399 11.4238 6.74712 11.4709 6.60325C11.5351 6.40816 11.4187 6.20118 11.2116 6.14114C11.0045 6.08071 10.7846 6.19024 10.7203 6.38533C10.6733 6.52901 10.724 6.67883 10.8367 6.77206L10.1017 7.933C9.88029 8.28251 9.3195 8.20309 9.21737 7.80773L8.87878 6.49429C9.01556 6.43674 9.11116 6.30726 9.11116 6.15706C9.11116 5.95276 8.93545 5.7876 8.71835 5.7876C8.50145 5.7876 8.32553 5.95295 8.32553 6.15706C8.32553 6.30726 8.42114 6.43674 8.55812 6.49429L8.21933 7.80773C8.1174 8.20309 7.55641 8.28251 7.33503 7.933L6.60015 6.77206C6.71288 6.67883 6.76364 6.52901 6.71655 6.38533C6.65295 6.19024 6.43279 6.08071 6.22548 6.14114C6.01816 6.20099 5.90177 6.40816 5.96598 6.60325C6.01307 6.74712 6.14496 6.84399 6.29356 6.86107L6.38122 8.21019C6.40752 8.61629 5.89667 8.84782 5.57561 8.57524L4.50927 7.66962C4.58816 7.54953 4.58979 7.39204 4.4999 7.26774C4.37759 7.09912 4.13317 7.05558 3.95379 7.17048C3.7746 7.28539 3.72833 7.5152 3.85043 7.68401C3.94094 7.80812 4.0973 7.86432 4.24488 7.83919Z" fill="#4092E6"/>
			</g>
			<defs>
				<filter id="filter0_i_43361_46123" x="0.888672" y="0" width="17.3617" height="18.2128" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dx="1.3617" dy="2.38298"/>
					<feGaussianBlur stdDeviation="1.10638"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43361_46123"/>
				</filter>
				<filter id="filter1_f_43361_46123" x="1.05882" y="3.06419" width="15.3189" height="10.2129" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="1.3617" result="effect1_foregroundBlur_43361_46123"/>
				</filter>
				<clipPath id="clip0_43361_46123">
					<rect width="16" height="16" fill="white" transform="translate(0.888672)"/>
				</clipPath>
			</defs>
		</svg>

	)
}
