import React from 'react';
import styles from './Card.module.css';

const Card = ({ title, description, imageSrc }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardInner}>
        <div className={styles.cardImage}>
          <img src={imageSrc} alt={title} />
        </div>
        <div className={styles.cardContent}>
          <div className={styles.cardTitle}>{title}</div>
          <div className={styles.cardDescription}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;

