import React from 'react';
import styles from './FreeTrialComponent.module.css';
import evallo from '../mobile-1.svg';
import calender from '../mobile-2.svg';
import assignments from '../mobile-3.svg';
import invoicing from '../mobile-4.svg';
import mobileInvoicing from "../MobileInvoicing.svg";
import mobileEvallo from "../MobileEvallo.svg";

const FreeTrialComponent = ({setLeadFormTrigger}) => {
  return (
    <div className={styles.mainContainer}>
        <div className={styles.innerContainer}>
            <div className={styles.mobileView}>
                <div className={styles.mobileTextInfo}>
                    <div className={styles.mobileText}>Start your&nbsp;
                        <span className={styles.mobileTextEvallo}>Free Trial&nbsp;</span>
                    Today with Evallo&nbsp;</div>
                    <div className={styles.mobileCreditInfo}>No credit card required</div>
                </div>
                <button className={styles.mobileDemoButton} onClick={()=>{setLeadFormTrigger(true)}}>
                    Book Demo
                </button>
                <div className={styles.mobileImages}>
                    <img className={styles.mobileImage} src={mobileEvallo} alt="Evallo" />
                    <img className={styles.mobileImage1} src={calender} alt="Calendar" />
                    <img className={styles.mobileImage1} src={assignments} alt="Assignments" />
                    <img className={styles.mobileImage} src={mobileInvoicing} alt="Invoicing" />
                </div>
            </div>
            <div className={styles.desktopView}>
                <div className={styles.desktopImagesLeft}>
                    <img className={styles.desktopBigImage} src={calender} alt="Calendar" />
                </div>
                <div className={styles.content}>
                    <div className={styles.textInfo}>
                        <div className={styles.desktopText}>Start your&nbsp;
                            <span className={styles.textEvallo}>Free Trial&nbsp;</span>
                        Today
                        <div>With Evallo</div></div>
                        <div className={styles.creditInfo}>No credit card required</div>
                    </div>
                    <div className={styles.buttonImages}>
                        <img className={styles.desktopImage} src={invoicing} alt="Invoicing" />
                        <button className={styles.demoButton} onClick={()=>{setLeadFormTrigger(true)}}>Book Demo</button>
                        <img className={styles.desktopImage} src={evallo} alt="Evallo" />
                    </div>
                </div>
                <div className={styles.desktopImagesRight}>
                    <img className={styles.desktopBigImage} src={assignments} alt="Assignments" />
                </div>
            </div>
        </div>
    </div>
  );
};

export default FreeTrialComponent;
