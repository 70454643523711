export default function InfoMoreIcon(props){
	return(
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_44000_2600)">
				<path d="M8.00065 15.1663C11.6825 15.1663 14.6673 12.1816 14.6673 8.49967C14.6673 4.81778 11.6825 1.83301 8.00065 1.83301C4.31875 1.83301 1.33398 4.81778 1.33398 8.49967C1.33398 12.1816 4.31875 15.1663 8.00065 15.1663Z" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 11.1667V8.5" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 5.83301H8.00667" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_44000_2600">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>

	)
}
