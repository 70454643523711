export default function ExplorerMbIcon(props){
	return(
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_43361_46115)">
				<g filter="url(#filter0_i_43361_46115)">
					<rect x="0.611328" width="16" height="16" rx="2.7234" fill="white"/>
				</g>
				<g filter="url(#filter1_f_43361_46115)">
					<path d="M7.69033 5.8684C8.09995 5.13354 8.30479 4.76611 8.61099 4.76611C8.91719 4.76611 9.12204 5.13354 9.53166 5.8684L9.63767 6.05851C9.75403 6.26733 9.81224 6.37174 9.90299 6.44063C9.99374 6.50952 10.1068 6.5351 10.3329 6.58624L10.5386 6.63281C11.3341 6.81281 11.7318 6.90278 11.8265 7.2071C11.9211 7.51136 11.65 7.82842 11.1077 8.46262L10.9673 8.62665C10.8132 8.80685 10.7362 8.89695 10.7015 9.00846C10.6669 9.1199 10.6785 9.24015 10.7018 9.48056L10.723 9.69944C10.805 10.5455 10.846 10.9686 10.5982 11.1566C10.3505 11.3447 9.97815 11.1733 9.23335 10.8303L9.04067 10.7416C8.82897 10.6442 8.72315 10.5954 8.61099 10.5954C8.49884 10.5954 8.39302 10.6442 8.18132 10.7416L7.98863 10.8303C7.24386 11.1733 6.87146 11.3447 6.62372 11.1566C6.37599 10.9686 6.41698 10.5455 6.49897 9.69944L6.52018 9.48056C6.54348 9.24015 6.55513 9.1199 6.52046 9.00846C6.4858 8.89695 6.40875 8.80685 6.25465 8.62665L6.11435 8.46262C5.57204 7.82842 5.3009 7.51136 5.39552 7.2071C5.49015 6.90278 5.88789 6.81281 6.68336 6.63281L6.88915 6.58624C7.1152 6.5351 7.22823 6.50952 7.31899 6.44063C7.40974 6.37174 7.46795 6.26733 7.58431 6.05851L7.69033 5.8684Z" fill="#4092E6"/>
				</g>
				<path d="M7.69033 5.8684C8.09995 5.13354 8.30479 4.76611 8.61099 4.76611C8.91719 4.76611 9.12204 5.13354 9.53166 5.8684L9.63767 6.05851C9.75403 6.26733 9.81224 6.37174 9.90299 6.44063C9.99374 6.50952 10.1068 6.5351 10.3329 6.58624L10.5386 6.63281C11.3341 6.81281 11.7318 6.90278 11.8265 7.2071C11.9211 7.51136 11.65 7.82842 11.1077 8.46262L10.9673 8.62665C10.8132 8.80685 10.7362 8.89695 10.7015 9.00846C10.6669 9.1199 10.6785 9.24015 10.7018 9.48056L10.723 9.69944C10.805 10.5455 10.846 10.9686 10.5982 11.1566C10.3505 11.3447 9.97815 11.1733 9.23335 10.8303L9.04067 10.7416C8.82897 10.6442 8.72315 10.5954 8.61099 10.5954C8.49884 10.5954 8.39302 10.6442 8.18132 10.7416L7.98863 10.8303C7.24386 11.1733 6.87146 11.3447 6.62372 11.1566C6.37599 10.9686 6.41698 10.5455 6.49897 9.69944L6.52018 9.48056C6.54348 9.24015 6.55513 9.1199 6.52046 9.00846C6.4858 8.89695 6.40875 8.80685 6.25465 8.62665L6.11435 8.46262C5.57204 7.82842 5.3009 7.51136 5.39552 7.2071C5.49015 6.90278 5.88789 6.81281 6.68336 6.63281L6.88915 6.58624C7.1152 6.5351 7.22823 6.50952 7.31899 6.44063C7.40974 6.37174 7.46795 6.26733 7.58431 6.05851L7.69033 5.8684Z" fill="#4092E6"/>
			</g>
			<defs>
				<filter id="filter0_i_43361_46115" x="0.611328" y="0" width="17.3617" height="18.2128" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dx="1.3617" dy="2.38298"/>
					<feGaussianBlur stdDeviation="1.10638"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43361_46115"/>
				</filter>
				<filter id="filter1_f_43361_46115" x="3.19823" y="2.58739" width="10.8252" height="10.8257" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="1.08936" result="effect1_foregroundBlur_43361_46115"/>
				</filter>
				<clipPath id="clip0_43361_46115">
					<rect width="16" height="16" fill="white" transform="translate(0.611328)"/>
				</clipPath>
			</defs>
		</svg>

	)
}
