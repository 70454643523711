export default function ExplorerRoundedIcon(props){
	return(
		<svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_i_43338_45678)">
				<rect x="0.333008" width="38" height="38" rx="19" fill="#EEF6FF"/>
			</g>
			<path d="M17.469 14.4472C18.3784 12.8157 18.8332 12 19.513 12C20.1928 12 20.6476 12.8157 21.557 14.4472L21.7924 14.8693C22.0507 15.3329 22.1799 15.5647 22.3814 15.7177C22.5829 15.8706 22.8339 15.9274 23.3358 16.0409L23.7926 16.1443C25.5587 16.5439 26.4417 16.7437 26.6518 17.4193C26.8619 18.0948 26.2599 18.7987 25.056 20.2067L24.7443 20.5709C24.4023 20.971 24.2313 21.171 24.1543 21.4186C24.0773 21.666 24.1032 21.9329 24.1549 22.4667L24.202 22.9526C24.384 24.8311 24.4749 25.7704 23.925 26.1878C23.375 26.6054 22.5483 26.2247 20.8947 25.4633L20.4669 25.2663C19.9969 25.0501 19.762 24.9418 19.513 24.9418C19.264 24.9418 19.0291 25.0501 18.5591 25.2663L18.1313 25.4633C16.4778 26.2247 15.651 26.6054 15.101 26.1878C14.551 25.7704 14.642 24.8311 14.824 22.9526L14.8711 22.4667C14.9228 21.9329 14.9487 21.666 14.8718 21.4186C14.7948 21.171 14.6237 20.971 14.2816 20.5709L13.9701 20.2067C12.7661 18.7987 12.1642 18.0948 12.3742 17.4193C12.5843 16.7437 13.4673 16.5439 15.2334 16.1443L15.6903 16.0409C16.1922 15.9274 16.4431 15.8706 16.6446 15.7177C16.8461 15.5647 16.9753 15.3329 17.2336 14.8693L17.469 14.4472Z" fill="#0671E0"/>
			<defs>
				<filter id="filter0_i_43338_45678" x="0.333008" y="0" width="42" height="44.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dx="4" dy="7"/>
					<feGaussianBlur stdDeviation="3.25"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43338_45678"/>
				</filter>
			</defs>
		</svg>
	)
}
