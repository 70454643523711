export default function CircleTickIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path opacity="0.18" d="M16.9421 8.49008C16.9421 13.0725 13.2273 16.7873 8.64487 16.7873C4.06244 16.7873 0.347656 13.0725 0.347656 8.49008C0.347656 3.90766 4.06244 0.192871 8.64487 0.192871C13.2273 0.192871 16.9421 3.90766 16.9421 8.49008Z" fill="#7FBDFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7384 6.43887C12.9545 6.65489 12.9545 7.00512 12.7384 7.22114L8.82686 11.1327C8.25081 11.7088 7.31686 11.7088 6.74081 11.1327L4.93545 9.32735C4.71943 9.11134 4.71943 8.7611 4.93545 8.54508C5.15147 8.32907 5.5017 8.32907 5.71772 8.54508L7.52308 10.3504C7.66709 10.4945 7.90058 10.4945 8.04459 10.3504L11.9562 6.43887C12.1722 6.22285 12.5224 6.22285 12.7384 6.43887Z"
        fill="#0671E0"
      />
    </svg>
  )
}
