import React from 'react';
import './Carousel.css';
import nextPrep from '../../../../assets/images/CalendarV2/NextPrep.svg'
import ntpa from '../../../../assets/images/CalendarV2/NTPA.svg'
import reevesTutor from '../../../../assets/images/CalendarV2/ReevesTutor.svg'
import sevenSquare from '../../../../assets/images/CalendarV2/SeveSquare.svg'
import testPrep from '../../../../assets/images/CalendarV2/TestPrep.svg'


const Carousel = () => {
  return (
    <div className="carousel-container">
      <div className="carousel-frame-background"></div>
      <div className="carousel-frame">
        <div className="carousel-content">
          <div className="carousel-images">
            <img src={nextPrep} alt="Logo 1" className="carousel-image" />
            <img src={ntpa} alt="Logo 2" className="carousel-image" />
            <img src={reevesTutor} alt="Logo 3" className="carousel-image" />
            <img src={sevenSquare} alt="Logo 4" className="carousel-image" />
            <img src={testPrep} alt="Logo 5" className="carousel-image" />
            {/* Duplicate images for continuous scrolling */}
            <img src={nextPrep} alt="Logo 1" className="carousel-image" />
            <img src={ntpa} alt="Logo 2" className="carousel-image" />
            <img src={reevesTutor} alt="Logo 3" className="carousel-image" />
            <img src={sevenSquare} alt="Logo 4" className="carousel-image" />
            <img src={testPrep} alt="Logo 5" className="carousel-image" />
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;

