import styles from "./CardSubHeader.module.css"
const CardSubHeader = () => {
  return (
    <>
      <h4 className={styles.container}>
        Compare
        <span className={styles.planText}>&nbsp;Plans</span>
      </h4>
      <h4 className={styles.mobileContainer}>Plan Comparison</h4>
    </>
  )
}

export default CardSubHeader
