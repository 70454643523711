import React, { useState } from 'react';
import styles from './InvoicingFeaturesMobile.module.css';
import separator from '../separator.svg';
import DollarIcon from '../dollarIcon.svg';
import SplitIcon from '../splitIcon.svg';
import CheckIcon from '../checkIcon.svg';
import PaymentSlips from '../AdvancePaymentSplits.svg';
import TransactionFee from '../HiddenFee.svg';
import FlexibleInvoicing from '../FlexibleInvoicingOption.svg';
import chevronUp from '../chevron-up.svg';
import chevronDown from '../chevron-down.svg';


const CardArea = ({info, setLeadFormTrigger}) => {
    return (
        <div className={styles.cardComponent}>
            <div className={styles.cardDescription}>
                {
                    info?.description
                }
                <button className={styles.demoButton} onClick={() => setLeadFormTrigger(true)}>Book Demo</button>
            </div>
            <div className={styles.imageContainer}>
                <img className={styles.cardImage} src={info.image} />
            </div>
        </div>
    )
}


const ToggleCards = ({ info, setLeadFormTrigger }) => {
    const [toggle, setToggle] = useState(info?.toggle || false);
    const handleToggle = () => {
        setToggle(!toggle);
    }
    return (
        <div className={styles.toggleArea}>
            <div className={`${styles.toggleComponent} ${toggle && styles.active}`}>
                <div className={styles.iconContainer}>
                    <img src={info?.icon} />
                </div>
                <div className={styles.headerArea}>
                    <div className={styles.header}>{info.heading}</div>
                    <div className={styles.arrows} onClick={handleToggle}>
                        {
                            toggle ? 
                            <img src={chevronUp} /> :
                            <img src={chevronDown} />
                        }
                    </div>
                </div>
            </div>
            {
                toggle && <CardArea setLeadFormTrigger={setLeadFormTrigger} info={info.details} />
            }
        </div>
    );
}

const InvoicingFeaturesMobile = ({setLeadFormTrigger}) => {
  const toggleData = [
    {
        heading: "Flat 2.9% Transactions - No Hidden Fees",
        icon: DollarIcon,
        toggle: true,
        details: {
            description: "No added cents, no revenue sharing. Just a simple, transparent rate that keeps more money in your pocket.",
            image: TransactionFee
        }
    },
    {
        heading: "Flexible Invoicing Options",
        icon: CheckIcon,
        details: {
            description: "Manual or automated, single charge or subscription-style—choose the invoicing method that fits your business model best.",
            image: FlexibleInvoicing
        }
    },
    {
        heading: "Advance Payment Splits",
        icon: SplitIcon,
        details: {
            description: "Collect deposits or partial payments upfront. Secure commitments from clients and reduce payment delays.",
            image: PaymentSlips
        }
    }
  ]
  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainerSavings}>
            <div className={styles.textInfo}>
                <div className={styles.heading}>
                    <div className={styles.headingBlue}>
                        Invoicing Features
                    </div>
                    <div className={styles.headingBlack}>
                        Built for Tutors
                    </div>
                </div>
                <div className={styles.description}>
                    All the tools you need to simplify payments, drive growth, and stay in control.
                </div>
            </div>
            <img className={styles.separator} src={separator} />
            {
                toggleData.map((info, idx) => <ToggleCards key={idx} info={info} setLeadFormTrigger={setLeadFormTrigger} />)
            }
      </div>
    </div>
  );
};

export default InvoicingFeaturesMobile;
