import { useEffect, useRef, useState } from "react"
import { EvChevronDownIcon, EvChevronUpIcon } from "../../../assets/icons/ChevronIcons"
import useOutsideAlerter from "../../../Hooks/useOutsideAlerter"
import PencilIcon from "../Icons/PencilIcon"
import ThreeDotIcon from "../Icons/ThreeDotIcon"
import styles from "./SizeDuration.module.css"

function SavingsIndicator() {
  return (
    <div className={styles.savingsIndicator}>
      <h5 className={styles.savingsText}>Save 15%</h5>
    </div>
  )
}

function DurationSelectorV2({ selectedPlanType, setSelectedPlanType }) {
  return (
    <div className={`${styles.durationSelector} ${selectedPlanType === "annual" ? styles.annualGradient : styles.quarterlyGradient}`} onClick={() => setSelectedPlanType((prev) => (prev === "annual" ? "quarterly" : "annual"))}>
      <button className={`${selectedPlanType === "quarterly" ? styles.activeButton : styles.inactiveButton}`}>Quarterly</button>
      <button className={`${selectedPlanType === "annual" ? styles.activeButton : styles.inactiveButton}`}>Yearly</button>
    </div>
  )
}

const TIER_SIZEMAPPING = {
  B0: "2-5",
  B1: "6-10",
  B2: "11-20",
  B3: "21-50",
}

function SizeSelector({ teamSizeTierSelected, setTeamSizeTierSelected }) {
  const ref = useRef()
  const mobileRef = useRef()

  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [isDDOpen, setISDDOpen] = useState(false)
  const [isMobileDDOpen, setIsMobileDDOpen] = useState(false)
  const [tempTSTSelected, setTempTSTSelected] = useState("B0")
  const [hasBeenEdited, setHasBeenEdited] = useState(false)
  const [hasBeenSelected, setHasBeenSelected] = useState(false)

  useEffect(() => {
    setTempTSTSelected(teamSizeTierSelected)
  }, [teamSizeTierSelected])

  useOutsideAlerter(ref, () => {
    setIsEditorOpen(false)
    setISDDOpen(false)
  })

  useOutsideAlerter(mobileRef, () => {
    setIsMobileDDOpen(false)
  })

  return (
    <>
      {/* Mobile Selector */}
      <div className={styles.mobileSelector} ref={mobileRef}>
        <div
          className={styles.mobileSelectorHeader}
          onClick={() => {
            setIsMobileDDOpen((prev) => !prev)
          }}
        >
          <span className={styles.mobileSelectorText}>{TIER_SIZEMAPPING[teamSizeTierSelected]} Tutors</span>
          {isMobileDDOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon />}
        </div>
        {isMobileDDOpen && (
          <div className={styles.mobileDropdown}>
            {Object.entries(TIER_SIZEMAPPING).map(([tier, size]) => (
              <div
                key={tier}
                className={`${styles.mobileDropdownItem} ${tempTSTSelected === tier ? styles.selectedItem : ""}`}
                onClick={() => {
                  setTeamSizeTierSelected(tier)
                  setIsMobileDDOpen(false)
                }}
              >
                {size} Tutors
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Desktop Selector */}
      <div className={styles.desktopContainer}>
        <h5 className={`${styles.teamSizeText} ${isEditorOpen || isDDOpen ? styles.activeOpacity : styles.inactiveOpacity}`}>Team Size</h5>
        <div className={`${styles.desktopSelector} ${isDDOpen ? styles.selectorActive : ""}`} ref={ref}>
          <div
            className={styles.selectorHeader}
            onClick={() => {
              if (!hasBeenEdited) {
                setIsEditorOpen((prev) => !prev)
              } else {
                setISDDOpen((prev) => !prev)
              }
            }}
          >
            <span className={styles.selectorText}>{TIER_SIZEMAPPING[tempTSTSelected]}</span>
            {hasBeenEdited ? isDDOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon /> : <ThreeDotIcon />}
          </div>
          {isEditorOpen && (
            <div
              className={styles.editorPopup}
              onClick={() => {
                setISDDOpen(true)
                setIsEditorOpen(false)
                setHasBeenEdited(true)
              }}
            >
              <PencilIcon />
              <span className={styles.editorText}>Edit team size</span>
            </div>
          )}
          {isDDOpen && (
            <div className={styles.dropdown}>
              {Object.entries(TIER_SIZEMAPPING).map(([tier, size]) => (
                <div
                  key={tier}
                  className={`${styles.dropdownItem} ${tempTSTSelected === tier ? styles.selectedItem : ""}`}
                  onClick={() => {
                    setTempTSTSelected(tier)
                    setHasBeenSelected(true)
                    setISDDOpen(false)
                  }}
                >
                  {size}
                </div>
              ))}
            </div>
          )}
        </div>
        <button
          className={styles.saveButton}
          disabled={tempTSTSelected === teamSizeTierSelected && !hasBeenSelected}
          hidden={!hasBeenEdited}
          onClick={() => {
            setTeamSizeTierSelected(tempTSTSelected)
            setHasBeenEdited(false)
            setHasBeenSelected(false)
          }}
        >
          Save
        </button>
      </div>
    </>
  )
}

function SizeDuration({ selectedPlanType, setSelectedPlanType, teamSizeTierSelected, setTeamSizeTierSelected, isIndividual }) {
  return (
    <div className={`${styles.sizeDurationContainer} ${isIndividual ? styles.individualMode : ""}`}>
      {!isIndividual && <SizeSelector {...{ teamSizeTierSelected, setTeamSizeTierSelected }} />}
      <div className={styles.durationSavings}>
        <DurationSelectorV2 {...{ selectedPlanType, setSelectedPlanType }} />
        <SavingsIndicator />
      </div>
    </div>
  )
}

export default SizeDuration
