export default function AramadaRoundedIcon(props){
	return(
		<div className="rounded-full flex items-center justify-center bg-[#EEF6FF] h-[38px] w-[38px] max-h-[38px] max-w-[38px]">
			<svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.937277 4.73516L3.13799 11C5.35646 10.5227 7.65051 10.2695 10.0003 10.2695C12.3497 10.2695 14.6437 10.5227 16.8622 11L19.0629 4.73516C19.3623 4.79271 19.6791 4.66343 19.8616 4.37653C20.1094 3.98692 20.0152 3.45651 19.6527 3.19131C19.2892 2.92566 18.7949 3.02616 18.5471 3.41578C18.3646 3.70223 18.3679 4.06573 18.5273 4.34333L16.3671 6.43353C15.7171 7.06267 14.6821 6.52827 14.7354 5.59098L14.9126 2.4776C15.2141 2.4382 15.4808 2.21461 15.5762 1.88255C15.7063 1.43228 15.4705 0.954558 15.0509 0.815978C14.6314 0.676514 14.1858 0.929322 14.0557 1.37959C13.9603 1.71121 14.0631 2.05699 14.2915 2.27217L12.8023 4.95166C12.3538 5.75834 11.2177 5.57505 11.0108 4.66255L10.3249 1.63107C10.602 1.49825 10.7957 1.1994 10.7957 0.852726C10.7957 0.381203 10.4397 0 9.9999 0C9.5605 0 9.20411 0.381646 9.20411 0.852726C9.20411 1.1994 9.39779 1.49825 9.67531 1.63107L8.98895 4.66255C8.78246 5.57505 7.64597 5.75834 7.19748 4.95166L5.70873 2.27217C5.9371 2.05699 6.03993 1.71121 5.94454 1.37959C5.81569 0.929322 5.36968 0.676514 4.94969 0.815978C4.5297 0.954115 4.2939 1.43228 4.42398 1.88255C4.51938 2.21461 4.78657 2.4382 5.08762 2.4776L5.2652 5.59143C5.31847 6.52872 4.28357 7.06311 3.63314 6.43397L1.4729 4.34377C1.63272 4.06661 1.63602 3.70312 1.4539 3.41622C1.20612 3.02705 0.710969 2.92654 0.347555 3.19175C-0.015445 3.45695 -0.109191 3.98736 0.138178 4.37698C0.321537 4.66343 0.638287 4.79316 0.937277 4.73516Z" fill="#0671E0"/>
			</svg>

		</div>
	)
}
