import React from 'react'

function IF1X() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1152_25654)">
<g opacity="0.11" filter="url(#filter0_d_1152_25654)">
<circle cx="20" cy="20" r="20" fill="#0671E0"/>
</g>
<g filter="url(#filter1_d_1152_25654)">
<circle cx="19.8281" cy="19.8271" r="17" fill="white"/>
</g>
<path d="M20.0797 26.4227C19.0173 26.4227 18.0478 26.2085 17.1713 25.7801C16.2948 25.3517 15.571 24.761 15 24.008L16.0757 22.8396C16.6733 23.5796 17.2908 24.1249 17.9283 24.4754C18.579 24.8259 19.3426 25.0011 20.2191 25.0011C20.8167 25.0011 21.3612 24.9038 21.8526 24.709C22.344 24.5143 22.7291 24.2417 23.008 23.8912C23.2869 23.5407 23.4263 23.1382 23.4263 22.6838C23.4263 22.3982 23.3798 22.1386 23.2869 21.9049C23.1939 21.6712 23.0478 21.4635 22.8486 21.2818C22.6627 21.1 22.4236 20.9378 22.1315 20.795C21.8526 20.6522 21.5206 20.5223 21.1355 20.4055C20.7503 20.2887 20.3121 20.1718 19.8207 20.055C18.9177 19.8603 18.1541 19.6006 17.5299 19.2761C16.919 18.9385 16.4542 18.5166 16.1355 18.0103C15.83 17.491 15.6773 16.8744 15.6773 16.1604C15.6773 15.6281 15.7902 15.1348 16.0159 14.6804C16.255 14.226 16.5803 13.8366 16.992 13.512C17.4037 13.1745 17.8884 12.9149 18.4462 12.7331C19.004 12.5514 19.6016 12.4605 20.239 12.4605C21.3147 12.4605 22.2311 12.6617 22.988 13.0641C23.745 13.4536 24.3493 13.9859 24.8008 14.6609L23.7052 15.732C23.3068 15.1607 22.8154 14.7064 22.2311 14.3688C21.6467 14.0313 20.9761 13.8625 20.2191 13.8625C19.6348 13.8625 19.1169 13.9599 18.6653 14.1546C18.2271 14.3494 17.8818 14.622 17.6295 14.9725C17.3772 15.31 17.251 15.6995 17.251 16.1409C17.251 16.4654 17.3108 16.751 17.4303 16.9977C17.5498 17.2314 17.7291 17.4391 17.9681 17.6208C18.2205 17.8026 18.5591 17.9714 18.9841 18.1271C19.409 18.2829 19.9203 18.4322 20.5179 18.575C21.2218 18.7308 21.846 18.9255 22.3904 19.1592C22.9482 19.3929 23.4197 19.6655 23.8048 19.9771C24.2032 20.2757 24.502 20.6262 24.7012 21.0286C24.9004 21.4311 25 21.8855 25 22.3918C25 23.1966 24.7942 23.9042 24.3825 24.5143C23.9708 25.1115 23.3931 25.5788 22.6494 25.9164C21.919 26.2539 21.0624 26.4227 20.0797 26.4227ZM19.243 28V19.1592L20.6972 19.8018V28H19.243ZM21.1155 19.8018L19.6813 19.1592V11H21.1155V19.8018Z" fill="#3B4045"/>
</g>
<defs>
<filter id="filter0_d_1152_25654" x="-35" y="-34" width="110" height="110" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="17.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1152_25654"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1152_25654" result="shape"/>
</filter>
<filter id="filter1_d_1152_25654" x="-32.1719" y="-31.1729" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="17.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1152_25654"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1152_25654" result="shape"/>
</filter>
<clipPath id="clip0_1152_25654">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default IF1X