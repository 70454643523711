import React, { useMemo } from "react"
import { Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { content } from "./cardContent"
import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider"
import CircleTickIcon from "../Icons/CircleTickIcon"
import InfoMoreIcon from "../Icons/InfoMoreIcon"
import ArmadaIcon from "../PackageTierIcons/ArmadaIcon"
import CrewIcon from "../PackageTierIcons/CrewIcon"
import ExplorerIcon from "../PackageTierIcons/ExplorerIcon"

import styles from "./PlanCard.module.css"

const TIER_NAME_MAPPING = {
  individual: {
    P0: "Explorer",
    P1: "Builder",
    P2: "Achiever",
  },
  business: {
    P0: "Explorer",
    P1: "Crew",
    P2: "Armada",
  },
}

const MostPopIndicator = () => <div className={styles.mostPopularIndicator}>Most Popular</div>

const PackageHeader = ({ PackageIcon, packageName, description, creditCardReq, mostPopular }) => {
  return (
    <div style={{ background: mostPopular && "#74B2F5" }} className={styles.header}>
      <div className={styles.packageHeaderContainer}>
        <PackageIcon />
        <div style={{ color: mostPopular ? "white" : "" }} className={styles.packageHeadingText}>
          {packageName}
        </div>
        {mostPopular && <MostPopIndicator />}
      </div>
      <p style={{ color: mostPopular && "#fff" }} className={styles.packageDescription}>
        {description}
        <span style={{ color: mostPopular && "#fff" }} className={styles.packageCreditCardText}>
          {creditCardReq ? " Credit Card required." : " Credit Card NOT required."}
        </span>
      </p>
    </div>
  )
}

const PackageDetails = ({ price, discountedPrice, selectedPlanType }) => (
  <div className={styles.packageDetails}>
    <h3 className={styles.price}>
      {price > 0 ? (
        <>
          <span className={styles.dollar}>$</span>
          <span className={styles.planPrice}>{price}</span>
        </>
      ) : (
        "Free"
      )}
    </h3>
    <div className={styles.discountContainer}>
      {price > 0 && (
        <div className={styles.discountSubContainer}>
          {discountedPrice >= 0 && <span className={styles.discountedPrice}>${discountedPrice}</span>}
          <span className={styles.monthContainer}>/month</span>
        </div>
      )}
      {price > 0 && (
        <span className={styles.monthContainer}>
          billed {selectedPlanType}
          {selectedPlanType === "annual" ? "ly" : ""}
        </span>
      )}
    </div>
  </div>
)

const FeatureItem = ({ displayText }) => (
  <div className={styles.featureItem}>
    <CircleTickIcon />
    <span className={styles.featureText}>{displayText}</span>
  </div>
)

const PackageButton = ({ buttonText, buttonType, onClick }) => (
  <div className={styles.buttonDiv}>
    <button className={`${styles.button} ${buttonType === "free" ? styles.buttonFree : styles.buttonPaid}`} onClick={onClick}>
      {buttonText}
    </button>
  </div>
)

const PackageCard = (props) => {
  const { features, buttonText, buttonType, onButtonClick, mostPopular, description, creditCardReq } = props

  return (
    <div className={`${styles.card} ${mostPopular && styles.mostPopular}`}>
      <div className={styles.cardHeadDiv1}>
        <div className={styles.cardHeadDiv}>
          <PackageHeader {...props} />
          <PackageDetails {...props} />
        </div>
        <div style={{ color: mostPopular && "#fff" }} className={styles.cardMobileDescription}>
          <p className={styles.cardMobileDescriptionText}>{description}</p>
          <p style={{ color: mostPopular && "#fff" }} className={styles.packageCreditCardText}>
            {creditCardReq ? " Credit Card required." : " Credit Card NOT required."}
          </p>
        </div>
      </div>
      <HorizontalDivider />
      <div className={styles.inclusionText}>What's Included</div>
      <div className={styles.featureDiv}>
        {features?.map((feature) => (
          <FeatureItem {...feature} />
        ))}
      </div>
      <PackageButton buttonType={buttonType} buttonText={buttonText} onClick={onButtonClick} />
    </div>
  )
}

const PlanCard = ({ requiredPrices, teamSizeTierSelected, requiredPricesContrasting, selectedPlanType, chosenProduct }) => {
  const navigate = useNavigate()
  function handleProductSelection() {
    window.open(`https://app.evallo.org/signup?tstSelected=${teamSizeTierSelected}&chosenProduct=${chosenProduct}`)
  }

  const productFeatures = useMemo(() => {
    if (!requiredPrices || !requiredPricesContrasting || !chosenProduct) return null
    const features = content(requiredPrices, chosenProduct, selectedPlanType)
    const selectedEntity = requiredPrices["P0"].lookup_key.split("_")[2]
    const tierNameSubKey = selectedEntity.includes("I") ? "individual" : "business"

    return {
      P0: {
        packageName: TIER_NAME_MAPPING[tierNameSubKey].P0,
        PackageIcon: ExplorerIcon,
        description: "Experience powerful features for free.",
        creditCardReq: false,
        price: 0,
        features: features.P0,
        discountedPrice: null,
        buttonText: "Get Started for Free",
        buttonType: "free",
      },
      P1: {
        packageName: TIER_NAME_MAPPING[tierNameSubKey].P1,
        PackageIcon: CrewIcon,
        description: "Ideal for freelance tutors and small businesses.",
        creditCardReq: true,
        price: parseFloat(requiredPrices.P1.unit_amount / (100 * (selectedPlanType === "quarterly" ? 3 : 12))).toFixed(2),
        features: features.P1,
        discountedPrice: selectedPlanType === "annual" ? parseFloat(requiredPricesContrasting.P1.unit_amount / 300).toFixed(0) : 0,
        buttonText: "Free 14-day trial",
        buttonType: "free",
      },
      P2: {
        packageName: TIER_NAME_MAPPING[tierNameSubKey].P2,
        PackageIcon: ArmadaIcon,
        description: "Ideal for larger businesses and enterprises.",
        creditCardReq: true,
        price: parseFloat(requiredPrices.P2.unit_amount / (100 * (selectedPlanType === "quarterly" ? 3 : 12))).toFixed(2),
        features: features.P2,
        buttonText: "Free 14-day trial",
        discountedPrice: selectedPlanType === "annual" ? parseFloat(requiredPricesContrasting.P2.unit_amount / 300).toFixed(0) : 0,
        buttonType: "paid",
        mostPopular: true,
      },
    }
  }, [requiredPrices, requiredPricesContrasting, selectedPlanType, chosenProduct])

  if (!productFeatures) return <div>Loading...</div>

  return (
    <div className={`${styles.container} ${styles.containerDesktop}`}>
      {Object.values(productFeatures).map((props, index) => (
        <PackageCard key={index} {...props} selectedPlanType={selectedPlanType} onButtonClick={handleProductSelection} />
      ))}
    </div>
  )
}

export default PlanCard
