import React from 'react'
import styles from './calendarFeatureCard.module.css'

const CalendarFeatureCard = ({ summary, setActivePopup }) => {
    return (
        <div className={styles.calendarFeatureCard}>
            <div>{summary}</div>
            <button onClick={() => setActivePopup(true)}> <span>Book Demo</span> </button>
        </div>
    )
}

export default CalendarFeatureCard