export default function BusinessIcon(props) {
  return (
    <svg {...props} width="73" height="61" viewBox="0 0 74 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.6418 22.4241C42.834 22.4241 47.8538 17.4043 47.8538 11.2121C47.8538 5.01981 42.834 0 36.6418 0C30.4495 0 25.4297 5.01981 25.4297 11.2121C25.4297 17.4043 30.4495 22.4241 36.6418 22.4241Z" fill="#0671E0" />
      <path
        d="M44.9765 23.9185C42.8032 25.748 40.1434 26.5504 36.6436 26.5504C33.1491 26.5504 30.8007 25.761 28.2797 23.8833C20.4376 25.3142 17.7539 33.0091 17.7539 39.0033V41.4957C17.7539 43.7785 18.8983 45.9207 20.8201 47.1521C21.413 47.5319 22.1094 47.9184 22.9222 48.2904C23.4619 55.3882 29.4098 60.9984 36.6434 60.9984C43.8771 60.9984 49.8251 55.388 50.3645 48.29C51.139 47.9356 51.808 47.5681 52.3824 47.2058C54.3429 45.9679 55.5331 43.8137 55.5331 41.4946V39.0033C55.5332 33.4851 53.1181 25.4715 44.9765 23.9185ZM36.6434 58.6156C30.3693 58.6156 25.2651 53.5113 25.2651 47.2373C25.2651 40.9634 30.3694 35.8591 36.6434 35.8591C42.9173 35.8591 48.0215 40.9634 48.0215 47.2373C48.0215 53.5113 42.9173 58.6156 36.6434 58.6156Z"
        fill="#0671E0"
      />
      <path d="M59.2545 16.9866C63.8543 16.9866 67.5832 13.2577 67.5832 8.65783C67.5832 4.058 63.8543 0.329102 59.2545 0.329102C54.6547 0.329102 50.9258 4.058 50.9258 8.65783C50.9258 13.2577 54.6547 16.9866 59.2545 16.9866Z" fill="#C8CEE3" />
      <path
        d="M65.4463 18.0997C63.8313 19.4579 61.8552 20.0541 59.2559 20.0541C56.6599 20.0541 54.9159 19.4677 53.0429 18.0737C50.6723 18.5062 48.9358 19.7094 47.7109 21.2843C51.3042 22.4897 54.1929 24.9145 56.144 28.3885C58.1384 31.9405 58.6609 35.6628 58.7639 38.029C58.9265 38.0311 59.0901 38.0323 59.2559 38.0323C65.3477 38.0323 68.929 36.672 70.9473 35.3983C72.403 34.4781 73.2876 32.8781 73.2876 31.1558V29.3054C73.2876 25.206 71.4936 19.253 65.4463 18.0997Z"
        fill="#C8CEE3"
      />
      <path d="M14.0319 16.9866C18.6317 16.9866 22.3606 13.2577 22.3606 8.65783C22.3606 4.058 18.6317 0.329102 14.0319 0.329102C9.43202 0.329102 5.70312 4.058 5.70312 8.65783C5.70312 13.2577 9.43202 16.9866 14.0319 16.9866Z" fill="#C8CEE3" />
      <path
        d="M2.34034 35.3983C4.35864 36.672 7.9399 38.0323 14.0316 38.0323C14.1974 38.0323 14.3611 38.0312 14.5237 38.029C14.6267 35.6628 15.1492 31.9405 17.1436 28.3885C19.0947 24.9145 21.9833 22.4897 25.5766 21.2843C24.3518 19.7094 22.6153 18.5062 20.2447 18.0737C18.3717 19.4677 16.6276 20.0541 14.0316 20.0541C11.4323 20.0541 9.45636 19.4579 7.84125 18.0997C1.79395 19.253 0 25.206 0 29.3055V31.1558C8.87129e-05 32.8781 0.884645 34.4781 2.34034 35.3983Z"
        fill="#C8CEE3"
      />
      <path d="M37.1719 42.8936V52.425" stroke="#0671E0" stroke-width="2" stroke-linecap="round" />
      <path d="M32.4062 47.6592H41.9377" stroke="#0671E0" stroke-width="2" stroke-linecap="round" />
    </svg>
  )
}
