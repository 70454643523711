import React from "react";
import styles from "./BlueCard.module.css";
import BlueCardImage from './BlueCardImage.svg'

function BlueCard({setLeadFormTrigger}) {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.cardTitle}>
          Discover Seamless Scheduling with Evallo
        </div>
        <button className={styles.button} onClick={() => setLeadFormTrigger(true)} >
          Book Demo
          <span className={styles.arrow}></span>
        </button>
      </div>
    </div>
  );
}

export default BlueCard;
