import React from "react";
const AssignmentsHeadIcon = (props) => {
  return (
    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
    <path d="M1.62131 30.0881L0.895967 7.72802C0.886469 7.4705 1.02918 7.34183 1.18865 7.29201C1.40198 7.22536 1.61435 7.35288 1.80261 7.47334L16.0466 16.5882C16.3244 16.766 16.6291 16.9067 16.9536 16.9661C20.4749 17.6105 21.6657 14.5283 21.0277 12.47C20.8828 12.0027 20.5116 11.6557 20.0987 11.3933L6.14772 2.52706C5.69493 2.2393 5.17682 1.57885 5.57792 1.22256C5.95599 0.886735 6.43009 1.14062 6.85688 1.41185L22.7909 11.5383C24.5277 12.642 24.7759 15.0791 23.2974 16.5104L7.01499 32.2714C5.03126 34.1916 1.71083 32.8475 1.62131 30.0881Z" fill="#0671E0"/>
    <rect width="1.12892" height="18.5895" rx="0.564459" transform="matrix(0.524843 -0.851199 0.833503 0.552515 2.14648 5.75574)" fill="#8BBDF2"/>
    <rect width="1.12892" height="18.6724" rx="0.564459" transform="matrix(0.524843 -0.851199 0.833503 0.552515 3.47656 3.7439)" fill="#8BBDF2"/>
    </svg>
    


  );
};

export default AssignmentsHeadIcon;
