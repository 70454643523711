import React, { useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import styles from "./newInvoicingFeatures.module.css"
import InvoicingFeaturesCard from "./InvoicingFeaturesCard"
import PaymentSlips from "../PaymentSlips.png"
import TransactionFee from "../TransactionFee.png"
import FlexibleInvoicing from "../FlexibleInvoicing.png"
//import SectionTitleAndDesc from '../SectionTitleAndDesc/SectionTitleAndDesc'

import CalendarFeatureCard from "../../../../components/CalendarFeatureCardV2/CalendarFeatureCard"

// import CustomBooking from '../../assets/images/CalendarV2/Custom Booking.png'
// import eventType from '../../assets/images/CalendarV2/EventType.png'
// import integrations from '../../assets/images/CalendarV2/Integrations.png'
// import calendarAnalitycs from '../../assets/images/CalendarV2/CalAnalitycxs.png'
//import IntegrationIcon from '../../assets/images/CalendarV2/desktopIcons/IntegrationIcon';
import IF1Icon from "./IF1Icon"
import IF1X from "./IF1X"
import IF2Icon from "./IF2Icon"
import IF3Icon from "./IF3Icon"
import IF1 from "./IF1.svg"
import IF2 from "./IF2.svg"
import IF3 from "./IF3.svg"
import IF3X from "./IF3X"
import IF2X from "./IF2X"

const cardSummary = ["No added cents, no revenue sharing. Just a simple, transparent rate that keeps more money in your pocket.", "Manual or automated, single charge or subscription-style—choose the invoicing method that fits your business model best.", "Collect deposits or partial payments upfront. Secure commitments from clients and reduce payment delays."]

// const ActiveCircle = () => {
//     return <div className={styles.activeCircle}>
//         &nbsp;
//     </div>
// }

const ActiveLine = () => {
  return <div className={styles.activeLine}></div>
}

const NewInvoicingFeatures = ({ setActivePopup, setLeadFormTrigger }) => {
  const [expanded, setExpanded] = useState("panel1")
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div>
      {/* <SectionTitleAndDesc title={"Our Features"} desc={"Why is Evallo the ultimate calendar management tool? Just take a look at our comprehensive features!"} customWidth={"692px"} /> */}
      <div className={styles.featureHeader}>
        <div className={styles.featureHeaderTitle}>Invoicing Features<span className={styles.blacktext}>Built for Tutors</span></div>
        <div className={styles.featureHeaderPara}>All the tools you need to simplify payments, drive growth, and stay in control.</div>
        <div className={styles.headerLine}></div>
      </div>


      <div className={styles.featuresContainer}>
        <div className={styles.featuresSectionLeft}>
          <Accordion className={styles.expandCard} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            {expanded === "panel1" && <ActiveLine />}
            <AccordionSummary
              className={styles.expandCardTabContainer}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                <div className={expanded === "panel1" ? styles.activeExpandCardTab : styles.expandCardTab}>
                  {expanded === "panel1" ? <IF1Icon /> : <IF1X />}
                  <div className={styles.activeExpandHeadingText}>Flat 2.9% Transactions—No Hidden Fees</div>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDeatils}>
              <Typography>
                <InvoicingFeaturesCard summary={cardSummary[0]}  setLeadFormTrigger={setLeadFormTrigger} />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.expandCard} expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            {expanded === "panel2" && <ActiveLine />}
            <AccordionSummary
              className={styles.expandCardTabContainer}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                <div className={expanded === "panel2" ? styles.activeExpandCardTab : styles.expandCardTab}>
                  {expanded === "panel2" ? <IF2X /> : <IF2Icon />}
                  <div>Flexible Invoicing Options</div>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDeatils}>
              <Typography>
                <InvoicingFeaturesCard summary={cardSummary[1]}  setLeadFormTrigger={setLeadFormTrigger} />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.expandCard} expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            {expanded === "panel3" && <ActiveLine />}
            <AccordionSummary
              className={styles.expandCardTabContainer}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                <div className={expanded === "panel3" ? styles.activeExpandCardTab : styles.expandCardTab}>
                  {expanded === "panel3" ? <IF3X /> : <IF3Icon /> }
                  <div>Advance Payment Splits</div>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDeatils}>
              <Typography>
                <InvoicingFeaturesCard summary={cardSummary[2]}  setLeadFormTrigger={setLeadFormTrigger} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <img className={styles.featuresSectionRight} src={expanded === "panel1" ? IF1 : expanded === "panel2" ? IF2 : expanded === "panel3" ? IF3 : ""} alt="" />
      </div>
    </div>
  )
}

export default NewInvoicingFeatures
