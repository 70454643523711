import React, { useState } from "react"
import styles from "./BookDemo.module.css"
import { toast } from "react-toastify"
import { insertQueryInDynamoDb } from "../../Utils/utils"

const BookDemo = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })

  const isEmail = (val) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regEmail.test(val)) {
      return false
    } else {
      return true
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (formData.firstName === "" || formData.email === "" || formData.lastName === "" || formData.phone === "") {
      toast.warn("Please fill required fields.")
      return
    }
    if (formData.phone.length > 10) {
      toast.warn("Enter correct phone number")
      return
    }
    if (isEmail(formData.email) == false) {
      toast.warn("Enter correct email.")
      return
    }
    setIsLoading(true)
    try {
      const result = await insertQueryInDynamoDb({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        message: "No Data",
        phone: formData.phone,
        queryFor: "sales",
      })

      window.history.pushState({}, "", `book-demo/call-scheduling`)
      toast.success("Thank you for entering the query.")

      window.ApolloMeetings.submit({
        onSuccess: () => {
          toast.success("Meeting booked successfully. Looking forward to meet you.")
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          })
        },
      })
    } catch (err) {
      console.log(err)
      toast.error("Oops an error occured")
    }
    setIsLoading(false)
  }
  return (
    <form className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Got a question?</div>
        <div className={styles.subHeading}>Fill out these details to build your broadcast</div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
          <input
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, firstName: e.target.value }
              })
            }}
            id="first_name"
            name="first_name"
            className={styles.inputStyles}
            type="text"
            placeholder="First Name*"
          />
          <input
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, lastName: e.target.value }
              })
            }}
            id="last_name"
            name="last_name"
            className={styles.inputStyles}
            type="text"
            placeholder="Last Name*"
          />
        </div>
        <input
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, email: e.target.value }
            })
          }}
          id="email"
          name="email"
          className={styles.inputStyles}
          type="email"
          placeholder="Email*"
        />
        <input
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, phone: e.target.value }
            })
          }}
          min={0}
          id="phone"
          name="phone"
          className={styles.inputStyles}
          type="number"
          placeholder="Phone Number*"
        />
      </div>
      <button onClick={(e) => handleFormSubmit(e)} type="submit" className={styles.demoButton}>
        {!isLoading ? "Book Demo" : "Sending..."}
      </button>
    </form>
  )
}

export default BookDemo
