import FeaturesCard from "../../../components/FeaturesCard/FeaturesCard"
import FeaturesHero from "../../../components/FeaturesHero/FeaturesHero"
import styles from "./newinvoicing.module.css"
import invoiceHero from "./invoiceHero.svg"
import autoCycles from "./autoCycles.svg"
import notifs from "./notifs1.png"
import clientMgmt from "./clientMgmt.svg"
import stripeInt from "./stripeInt.svg"
import manInvoice from "./manInvoicing.svg"
import customPrice from "./customPricing.svg"
import FeatureCardSmall from "../../../components/FeaturesCardSmall/FeaturesCardSmall"
import Meteorites from "../../../components/metorites/meteorites"
import { useEffect } from "react"
import useScrollAnimation from "../../../Hooks/useScrollAnimation"
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded"
import ContactFrom from "../../../components/ContactForm/ContactFrom"
import { useState } from "react"
import { ToastContainer } from "react-toastify"
import SlideOne from "./Components/SlideOne"
import BusinessStats from "./Components/BusinessStats"
import SavingsCalculator from "./Components/SavingCalculator"
import FreeTrialComponent from "./Components/FreeTrialComponent"
import CustomSolutions from "./Components/CustomSolutions"
import BookDemo from "../../../components/BookDemo/BookDemo"
import LeadForm from "../../../components/LeadForm/LeadForm"
import PopUp from "../../../components/PopUp/PopUp"
import InvoicingFeatures from "./Components/InvoicingFeatures"
import InvoicingFeaturesMobile from "./Components/InvoicingFeaturesMobile"
import CardSection from "./Components/CardSection"
import BusinessStatsmobile from "./Components/BusinessStatsmobile"
import HeroImage from "./HeroImage.svg"
import CalendarFeaturesSection from "../../../components/CalendarFeaturesSection/CalendarFeaturesSection"
import NewInvoicingFeatures from "./Components/newInvoicingFeatures"

export default function NewInvoicing(props) {
  const watchDemoButtonText = (
    <div className="d-none justify-content-between gap-2 align-items-center" style={{ pointerEvents: "none" }}>
      <PlayCircleOutlineRoundedIcon style={{ pointerEvents: "none" }} />
      Watch Demo
    </div>
  )
  const [vidTrigger, setVidTrigger] = useState(false)
  const [vidIdx, setVidIdx] = useState(0)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [leadFormTrigger, setLeadFormTrigger] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Function to check the screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
       // Adjust the breakpoint as needed
       console.log(window.innerWidth);
    }

    // Initialize the state and add event listener
    handleResize()
    window.addEventListener("resize", handleResize)

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    setLeadFormTrigger(false)
  }, [])

  useScrollAnimation(styles.fCard, styles.popIn, 0.85)

  return (
    <>
      <div className={`container-fluid h-100 ${styles.container} position-relative px-0`} id="top">
        <div className={`row`}>
          <div className="col-12">
            <SlideOne
              img={invoiceHero}
              heading="Purpose-built invoicing for tutors, with the lowest transaction fees."
              description="Automate your billing, keep more of what you earn, and refocus on teaching. Our tailored invoicing streamlines the entire payment process so you can grow your business without the overhead."
              primaryButtonText="Book Demo"
              secondaryButtonText="Start Trial"
              secondaryButtonUrl="https://app.evallo.org/signup"
              imageSrc={HeroImage}
              imageAlt="this is the iamge"
              setLeadFormTrigger={setLeadFormTrigger}
            />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            {!isMobile && <BusinessStats setLeadFormTrigger={setLeadFormTrigger} />}
            {isMobile && <BusinessStatsmobile setLeadFormTrigger={setLeadFormTrigger} />}
            
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            <NewInvoicingFeatures setLeadFormTrigger={setLeadFormTrigger} />
            <InvoicingFeaturesMobile setLeadFormTrigger={setLeadFormTrigger} />
          </div>
        </div>
       
      <div className={`row`}>
        <div className="col-12">
          <CardSection setLeadFormTrigger={setLeadFormTrigger}/>
        </div>
      </div>
        <div className={`row`}>
          <div className="col-12">
            <SavingsCalculator />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            <FreeTrialComponent setLeadFormTrigger={setLeadFormTrigger} />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            <CustomSolutions />
          </div>
        </div>

        <div className={styles.demoDiv}>
          <BookDemo />
        </div>

        {/* <div className='row p-0 m-0'>
                  <div className='col-12 p-0 m-0'>
                  <ContactFrom  {...{
                  firstName,
                  lastName,
                  email,
                  phone,
                  setFirstName,
                  setLastName,
                  setPhone,
                  message,
                  setMessage,
                  setEmail,
                  queryFor:"sales",
                }} />
                  </div>
              </div> */}
        {/* <Meteorites /> */}
      </div>
      {leadFormTrigger && (
        <PopUp trigger={leadFormTrigger} setTrigger={setLeadFormTrigger}>
          <LeadForm {...{ firstName, lastName, email, phone, setFirstName, setLastName, setPhone, message, setMessage, setEmail, setFormPopUpTrigger: setLeadFormTrigger }} />
        </PopUp>
      )}
    </>
  )
}
