import React from 'react'

function IF2Icon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1152_25673)">
    <g opacity="0.11" filter="url(#filter0_d_1152_25673)">
    <circle cx="20" cy="20" r="20" fill="#0671E0"/>
    </g>
    <g filter="url(#filter1_d_1152_25673)">
    <circle cx="19.8281" cy="19.8271" r="17" fill="#ffffff"/>
    </g>
    <path opacity="0.5" d="M23.754 11.8335H16.146C15.2189 11.8335 14.7554 11.8335 14.3815 11.9636C13.6726 12.2103 13.116 12.7832 12.8764 13.5131C12.75 13.8979 12.75 14.3751 12.75 15.3295V26.5328C12.75 27.2194 13.538 27.5837 14.0365 27.1276C14.3293 26.8596 14.7707 26.8596 15.0635 27.1276L15.45 27.4812C15.9633 27.9509 16.7367 27.9509 17.25 27.4812C17.7633 27.0116 18.5367 27.0116 19.05 27.4812C19.5633 27.9509 20.3367 27.9509 20.85 27.4812C21.3633 27.0116 22.1367 27.0116 22.65 27.4812C23.1633 27.9509 23.9367 27.9509 24.45 27.4812L24.8365 27.1276C25.1293 26.8596 25.5706 26.8596 25.8635 27.1276C26.362 27.5837 27.15 27.2194 27.15 26.5328V15.3295C27.15 14.3751 27.15 13.8979 27.0236 13.5131C26.784 12.7832 26.2274 12.2103 25.5185 11.9636C25.1446 11.8335 24.6811 11.8335 23.754 11.8335Z" stroke="black"/>
    <path d="M17.9531 18.5535L19.096 19.8335L21.9531 16.6335" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.3516 22.6334H23.5516" stroke="black" stroke-linecap="round"/>
    </g>
    <defs>
    <filter id="filter0_d_1152_25673" x="-35" y="-34" width="110" height="110" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="17.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1152_25673"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1152_25673" result="shape"/>
    </filter>
    <filter id="filter1_d_1152_25673" x="-32.1719" y="-31.1729" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="17.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1152_25673"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1152_25673" result="shape"/>
    </filter>
    <clipPath id="clip0_1152_25673">
    <rect width="40" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default IF2Icon