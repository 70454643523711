import React from 'react'

function IF3X() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1181_21591)">
<g opacity="0.11" filter="url(#filter0_d_1181_21591)">
<circle cx="20" cy="20" r="20" fill="#0671E0"/>
</g>
<g filter="url(#filter1_d_1181_21591)">
<circle cx="19.8281" cy="20.8271" r="17" fill="#0671E0"/>
</g>
<path d="M23.8624 14C22.6685 13.9985 21.5006 14.3692 20.5043 15.0658C20.4068 14.9976 20.307 14.9323 20.205 14.8699C19.2719 14.2998 18.2136 13.9998 17.1364 14C16.0591 14.0002 15.0009 14.3007 14.0681 14.8713C13.1352 15.4418 12.3606 16.2624 11.8221 17.2504C11.2835 18.2384 11 19.3592 11 20.5C11 21.6408 11.2835 22.7616 11.8221 23.7496C12.3606 24.7376 13.1352 25.5582 14.0681 26.1287C15.0009 26.6993 16.0591 26.9998 17.1364 27C18.2136 27.0002 19.2719 26.7002 20.205 26.1301C20.307 26.0677 20.4068 26.0024 20.5043 25.9342C21.5005 26.631 22.6685 27.0017 23.8624 27C24.6684 27 25.4665 26.8319 26.2111 26.5052C26.9558 26.1786 27.6324 25.6998 28.2023 25.0962C28.7723 24.4926 29.2244 23.7761 29.5328 22.9874C29.8412 22.1988 30 21.3536 30 20.5C30 19.6464 29.8412 18.8012 29.5328 18.0126C29.2244 17.2239 28.7723 16.5074 28.2023 15.9038C27.6324 15.3002 26.9558 14.8214 26.2111 14.4948C25.4665 14.1681 24.6684 14 23.8624 14ZM17.1362 26.1096C16.3238 26.1093 15.5224 25.9112 14.7939 25.5306C14.0655 25.1499 13.4294 24.5969 12.935 23.9143C12.4405 23.2317 12.101 22.4378 11.9425 21.5941C11.7841 20.7503 11.811 19.8792 12.0213 19.0483C12.2315 18.2173 12.6194 17.4486 13.1549 16.8018C13.6905 16.1549 14.3593 15.6472 15.1098 15.3178C15.8602 14.9884 16.6722 14.8463 17.4828 14.9023C18.2934 14.9584 19.081 15.2111 19.7846 15.641C19.8863 15.7034 19.9813 15.7746 20.0789 15.8449V25.1551C19.9813 25.2245 19.8855 25.2975 19.7846 25.3599C18.9792 25.8516 18.0658 26.1102 17.1362 26.1096ZM23.8624 26.1096C22.8121 26.1069 21.7869 25.77 20.9196 25.1426V15.8574C21.7869 15.2301 22.8121 14.8932 23.8624 14.8904C24.558 14.8904 25.2467 15.0355 25.8894 15.3174C26.532 15.5993 27.1159 16.0125 27.6078 16.5334C28.0997 17.0543 28.4898 17.6727 28.756 18.3533C29.0222 19.0339 29.1592 19.7633 29.1592 20.5C29.1592 21.2367 29.0222 21.9661 28.756 22.6467C28.4898 23.3273 28.0997 23.9457 27.6078 24.4666C27.1159 24.9875 26.532 25.4007 25.8894 25.6826C25.2467 25.9645 24.558 26.1096 23.8624 26.1096Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1181_21591" x="-35" y="-34" width="110" height="110" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="17.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1181_21591"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1181_21591" result="shape"/>
</filter>
<filter id="filter1_d_1181_21591" x="-32.1719" y="-30.1729" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="17.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1181_21591"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1181_21591" result="shape"/>
</filter>
<clipPath id="clip0_1181_21591">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>


  )
}

export default IF3X