export default function ArmadaIcon(props){
	return(
		<svg width="46" height="43" viewBox="0 0 46 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="1" width="44" height="43" rx="6" fill="#EEF6FF"/>
			<g opacity="0.48" filter="url(#filter0_f_44000_17511)">
				<path d="M9.85905 20.4899L13.0501 28.7655C16.2669 28.1351 19.5932 27.8005 23.0004 27.8005C26.4071 27.8005 29.7334 28.1351 32.9502 28.7655L36.1413 20.4899C36.5754 20.5659 37.0347 20.3951 37.2993 20.0161C37.6586 19.5015 37.5221 18.8008 36.9963 18.4505C36.4694 18.0996 35.7526 18.2323 35.3933 18.747C35.1287 19.1254 35.1335 19.6056 35.3646 19.9723L32.2322 22.7334C31.2897 23.5644 29.7891 22.8585 29.8664 21.6204L30.1232 17.5077C30.5604 17.4557 30.9472 17.1603 31.0855 16.7217C31.2742 16.1269 30.9322 15.4958 30.3239 15.3127C29.7155 15.1285 29.0694 15.4625 28.8807 16.0573C28.7424 16.4953 28.8915 16.9521 29.2226 17.2363L27.0634 20.7759C26.413 21.8415 24.7657 21.5993 24.4657 20.394L23.4711 16.3895C23.8729 16.214 24.1538 15.8192 24.1538 15.3613C24.1538 14.7384 23.6376 14.2349 22.9999 14.2349C22.3627 14.2349 21.846 14.739 21.846 15.3613C21.846 15.8192 22.1268 16.214 22.5292 16.3895L21.534 20.394C21.2346 21.5993 19.5867 21.8415 18.9364 20.7759L16.7777 17.2363C17.1088 16.9521 17.2579 16.4953 17.1196 16.0573C16.9327 15.4625 16.286 15.1285 15.6771 15.3127C15.0681 15.4952 14.7261 16.1269 14.9148 16.7217C15.0531 17.1603 15.4405 17.4557 15.8771 17.5077L16.1345 21.621C16.2118 22.8591 14.7112 23.565 13.7681 22.734L10.6357 19.9729C10.8674 19.6067 10.8722 19.1266 10.6082 18.7476C10.2489 18.2335 9.5309 18.1007 9.00396 18.4511C8.4776 18.8014 8.34167 19.5021 8.70036 20.0167C8.96623 20.3951 9.42552 20.5665 9.85905 20.4899Z" fill="#0D96F2"/>
			</g>
			<path d="M9.85905 20.4899L13.0501 28.7655C16.2669 28.1351 19.5932 27.8005 23.0004 27.8005C26.4071 27.8005 29.7334 28.1351 32.9502 28.7655L36.1413 20.4899C36.5754 20.5659 37.0347 20.3951 37.2993 20.0161C37.6586 19.5015 37.5221 18.8008 36.9963 18.4505C36.4694 18.0996 35.7526 18.2323 35.3933 18.747C35.1287 19.1254 35.1335 19.6056 35.3646 19.9723L32.2322 22.7334C31.2897 23.5644 29.7891 22.8585 29.8664 21.6204L30.1232 17.5077C30.5604 17.4557 30.9472 17.1603 31.0855 16.7217C31.2742 16.1269 30.9322 15.4958 30.3239 15.3127C29.7155 15.1285 29.0694 15.4625 28.8807 16.0573C28.7424 16.4953 28.8915 16.9521 29.2226 17.2363L27.0634 20.7759C26.413 21.8415 24.7657 21.5993 24.4657 20.394L23.4711 16.3895C23.8729 16.214 24.1538 15.8192 24.1538 15.3613C24.1538 14.7384 23.6376 14.2349 22.9999 14.2349C22.3627 14.2349 21.846 14.739 21.846 15.3613C21.846 15.8192 22.1268 16.214 22.5292 16.3895L21.534 20.394C21.2346 21.5993 19.5867 21.8415 18.9364 20.7759L16.7777 17.2363C17.1088 16.9521 17.2579 16.4953 17.1196 16.0573C16.9327 15.4625 16.286 15.1285 15.6771 15.3127C15.0681 15.4952 14.7261 16.1269 14.9148 16.7217C15.0531 17.1603 15.4405 17.4557 15.8771 17.5077L16.1345 21.621C16.2118 22.8591 14.7112 23.565 13.7681 22.734L10.6357 19.9729C10.8674 19.6067 10.8722 19.1266 10.6082 18.7476C10.2489 18.2335 9.5309 18.1007 9.00396 18.4511C8.4776 18.8014 8.34167 19.5021 8.70036 20.0167C8.96623 20.3951 9.42552 20.5665 9.85905 20.4899Z" fill="#0D96F2"/>
			<defs>
				<filter id="filter0_f_44000_17511" x="0.5" y="6.23486" width="45" height="30.5308" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_44000_17511"/>
				</filter>
			</defs>
		</svg>
	)
}
