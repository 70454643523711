import React from "react";
import Card from "./Card";
import Card1 from "./Card1.svg"
import Card2 from "./Card2.svg"
import Card3 from "./Card3.svg"
import styles from "./CardSection.module.css"
import BlueCard from "./BlueCard";

function CardSection({setLeadFormTrigger}) {
  return (
    <div className={styles.container}>
    <div className={styles.cardSection}>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
        <Card
          title="Credit System for Refunds & Rewards"
          description="Issue credits instead of refunds, incentivize referrals, and maintain a smooth financial flow without extra hassles."
          imageSrc={Card1}
        />
      </div>
      </div>
      <div className={styles.cardContainer}>
      <div className={styles.card}>
        <Card
          title="Powerful Analytics & Insights"
          description="Track earnings, client habits, and session history. Make informed decisions and optimize your tutoring practice."
          imageSrc={Card2}
        />
      </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
        <Card
          title="White-Labeled Invoice PDFs"
          description="Customize invoices with your branding, reinforcing a professional, polished image every time you bill."
          imageSrc={Card3}
        />
      </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
        <BlueCard setLeadFormTrigger={setLeadFormTrigger}/>
      </div>
      </div>
    </div>
    </div>
  );
}

export default CardSection;
