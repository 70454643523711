import React, { useState } from 'react';
import styles from './CustomSolutions.module.css';
import CustomSolutionsDesktop from './CustomSolutionsDesktop';
import IndividualTutorActive from "../IndividualTutorActive.svg";
import IndividualTutorInactive from "../IndividualTutorInactive.svg";
import PreSessionInvoicing from "../PreSessionInvoicing.svg";
import AutomatedReminders from "../AutomatedReminders.svg";
import WhitelabeledInvoice from "../WhitelabeledInvoice.svg";
import TutorsActive from "../TutorsActive.svg";
import TutorsInactive from "../TutorsInactive.svg";
import RobustAnalytics from "../RobustAnalytics.svg";
import MultipleInvoice from "../MultipleInvoice.svg";
import TransactionFee from "../TransactionFee.svg";
import CoachesInactive from "../CoachesInactive.svg";
import CoachesActive from "../CoachesActive.svg";
import FlexiblePayment from "../FlexiblePayment.svg";
import CreditSystem from "../CreditSystem.svg";
import CustomBranding from "../CustomBranding.svg";
import chevronUp from "../chevron-up.svg";
import chevronDown from "../chevron-down.svg";

const cardInfo = [
    {
        heading: "Individual Tutors",
        srcActive: IndividualTutorActive,
        srcInactive: IndividualTutorInactive,
        toggle: true,
        cards: [
            {
                heading: "Pre-Session Invoicing",
                description: "Secure payment before lessons start, reducing no-shows.",
                image: PreSessionInvoicing
            },
            {
                heading: "Automated Reminders",
                description: "Secure payment before lessons start, reducing no-shows.",
                image: AutomatedReminders
            },
            {
                heading: "White-Labeled Invoices",
                description: "Secure payment before lessons start, reducing no-shows.",
                image: WhitelabeledInvoice
            }
        ]
    },
    {
        heading: "Tutoring Companies",
        srcActive: TutorsActive,
        srcInactive: TutorsInactive,
        cards: [
            {
                heading: "2.9% Flat Transaction Fees",
                description: "Keep more of your hard-earned revenue—no hidden charges.",
                image: TransactionFee
            },
            {
                heading: "Robust Analytics",
                description: "Track performance, client behavior, and income trends with ease.",
                image: RobustAnalytics
            },
            {
                heading: "Multiple Invoice Types",
                description: "Choose from manual, automated, or subscription models to fit your business.",
                image: MultipleInvoice
            }
        ]
    },
    {
        heading: "Coaches & Consultants",
        srcActive: CoachesActive,
        srcInactive: CoachesInactive,
        cards: [
            {
                heading: "Flexible Payment Schedules",
                description: "Set recurring or milestone-based payments to suit your services.",
                image: FlexiblePayment
            },
            {
                heading: "Credit System for Loyalty",
                description: "Offer credits for referrals or future sessions, rewarding long-term clients.",
                image: CreditSystem
            },
            {
                heading: "Custom Branding",
                description: "Align invoices with your personal brand, reinforcing trust and credibility.",
                image: CustomBranding
            }
        ]
    }
]

const MobileInfoCards = ({ card }) => {
    return (
        <div className={styles.mobileCard}>
            <img src={card?.image} className={styles.cardImage} />
            <div className={styles.cardText}>
                <div className={styles.cardHeading}>{card?.heading}</div>
                <div className={styles.cardDescription}>{card?.description}</div>
            </div>
        </div>
    );
}

const CustomSolutionsMobile = () => {
    return (
        <div className={styles.mobileComponent}>
            <div className={styles.mobileTextInfo}>
                <div className={styles.mobileHeading}>Custom Solutions For All</div>
                <div className={styles.mobileDescription}>Tailored to fit your unique tutoring needs, our solutions adapt to every challenge. Empowering tutors, students, and administrators alike!</div>
                <div className={styles.mobileSeparator}></div>
            </div>
            <div className={styles.toggleCards}>
                {
                    cardInfo.map((info, idx) => <MobileToggleCards key={idx} info={info} />)
                }
            </div>
        </div>
    );
}

const MobileToggleCards = ({ info }) => {
    const [toggle, setToggle] = useState(info?.toggle || false);
    const handleToggle = () => {
        setToggle(!toggle);
    }
    return (
        <div>
            <div className={`${styles.toggleComponent} ${toggle && styles.active}`}>
                <div className={styles.nameImage}>
                    {
                        toggle ? 
                        <img className={styles.toggleImage} src={info?.srcActive} /> :
                        <img className={styles.toggleImage} src={info?.srcInactive} />
                    }
                    <div className={styles.nameArea}>
                        {info?.heading}
                    </div>
                </div>
                <div className={styles.arrows} onClick={handleToggle}>
                    {
                        toggle ? 
                        <img src={chevronUp} /> :
                        <img src={chevronDown} />
                    }
                </div>
            </div>
            {
                toggle && (
                    <div className={styles.cardsInfo}>
                        {info?.cards.map((card, idx) => <MobileInfoCards key={idx} card={card} />)}
                    </div>
                )
            }
        </div>
    );
}

const CustomSolutions = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.innerContainer}>
                <CustomSolutionsMobile />
                <CustomSolutionsDesktop />
            </div>
        </div>
    );
};

export default CustomSolutions;
