import { useEffect } from "react";

//detects if clicked outside elementref

export default function useOutsideAlerter(ref, handleClose, ref2, requiredArg) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
			if(ref2 && ref2.current && ref2.current.contains(event.target)){
				return;
			}
		if(requiredArg){
			handleClose(requiredArg)
			return
		}
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ref2, requiredArg]);
}
