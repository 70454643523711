import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import TutorIcon from "../../assets/OnBoarding/Tutor"
import BusinessIcon from "../../assets/OnBoarding/Business"
import GoIcon from "../../assets/OnBoarding/Goicon"
import styles from "./AccountCard.module.css"

const SelectAccountType = ({ icon: Icon, title, description, operation, link }) => {
  return (
    <div className={styles.card} onClick={operation}>
      <section className={styles.cardIconWrapper}>
        <Icon className={styles.cardIcon} />
      </section>
      <section className={`${styles.cardContent}`}>
        <h3 className={`${styles.cardTitle}`}>{title}</h3>
        <p className={`${styles.cardDescription}`}>{description}</p>
        <Link to={link} className={styles.cardLink}>
          <div className={styles.goAnim}>
            <GoIcon />
          </div>
        </Link>
      </section>
    </div>
  )
}

const SelectAccountCard = ({ setTeamSizeTierSelected, setStep }) => {
  const [signupStage, setSignupStage] = useState()

  const accountTypeData = [
    {
      icon: TutorIcon,
      title: "Individual Tutor",
      description: "If you are a freelancer or a solopreneur providing services.",
      operation: () => {
        setStep((prev) => prev + 2)
        setTeamSizeTierSelected("I0")
      },
      link: "/signup/org",
    },
    {
      icon: BusinessIcon,
      title: "Tutoring Business",
      description: "If you have a tutoring company with other tutors in your team.",
      operation: () => {
        setStep((prev) => prev + 1)
        setTeamSizeTierSelected("")
      },
      link: "/signup/select-team-size",
    },
  ]

  useEffect(() => {
    setSignupStage("initial")
  }, [])

  return (
    <div className={styles.container}>
      <div className={`${styles.contentWrapper}`}>
        <h2 className={`${styles.title}`}>Choose your business type</h2>
        <section className={styles.optionsWrapper}>
          {accountTypeData.map((item) => (
            <SelectAccountType key={item.title} icon={item.icon} title={item.title} description={item.description} operation={item.operation} link={item.link} />
          ))}
        </section>
      </div>
    </div>
  )
}

export default SelectAccountCard
