import React from "react"
import styles from "./SelectProduct.module.css"
import CalendarHeadIcon from "../../../assets/v2/CalendarHeadIcon"
import InvoicingHeadIcon from "../../../assets/v2/InvoicingHeadIcon"
import AssignmentsHeadIcon from "../../../assets/v2/AssignmentsHeadIcon"
const ProductState = ({ item, chosenProduct, setChosenProduct }) => {
  return (
    <div
      onClick={() => {
        setChosenProduct(item?.keyword)
      }}
      style={{ borderColor: chosenProduct === item?.keyword ? "#0771DF" : "#D5DEE7", filter: chosenProduct !== item?.keyword && "grayscale(100%)" }}
      className={styles.productContainer}
    >
      <div className={styles.iconDiv}>{item?.icon}</div>
      <div className={styles.titleDiv}>{item?.title}</div>
    </div>
  )
}
const SelectProduct = ({ chosenProduct, setChosenProduct }) => {
  const productData = [
    {
      icon: <InvoicingHeadIcon style={{ width: "20px" }} />,
      title: "invoicing",
      keyword: "invoicing",
    },
    {
      icon: <CalendarHeadIcon style={{ width: "20px" }} />,
      title: "calendar",
      keyword: "calendar",
    },
    {
      icon: <AssignmentsHeadIcon style={{ width: "20px" }} />,
      title: "prepwork",
      keyword: "assignments",
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <div className={styles.text1}>Our Simplified Pricing.</div>
        <div className={styles.text2}>Unlimited Users.</div>
      </div>
      <div className={styles.scrollDiv}>
        <div className={styles.productList}>
          {productData.map((item) => {
            return <ProductState {...{ item, chosenProduct, setChosenProduct }} />
          })}
        </div>
      </div>
    </div>
  )
}

export default SelectProduct
