import { Route, Routes } from "react-router-dom"
import Features from "./features/Features"
import Tutoring from "./Tutoring/Tutoring"
import Invoicing from "./Invoicing/Invoicing"
import NewInvoicing from "./Invoicing/newinvoicing"
import CrmProfile from "./CRM and Profile/CrmProfile"
import Dsat from "./DSAT/Dsat"

export default function ALLFeatures (props)  {
    return(
        <Routes>
            <Route index element={<Features />}></Route>
            <Route path="dsat" element={<Dsat />}></Route>
            <Route path="calendar" element={<Tutoring />}></Route>
            <Route path="crm" element={<CrmProfile />}></Route>
            {/* <Route path="invoicing" element={<Invoicing />}></Route> */}
            <Route path="invoicing" element={<NewInvoicing />}></Route>
        </Routes>
    )
}