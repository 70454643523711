export default function CrewRoundedIcon(props){
	return(
		<svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_i_43338_45673)">
				<rect x="0.666992" width="38" height="38" rx="19" fill="#EEF6FF"/>
			</g>
			<path d="M19.5155 21.5114H14.1765C13.7876 21.5114 13.5425 21.0858 13.7329 20.7411L20.0698 9.26547C20.3407 8.77484 21.0787 9.01466 21.0193 9.57404L20.3041 16.3186H25.643C26.032 16.3186 26.2771 16.7442 26.0867 17.0889L19.7497 28.5645C19.4788 29.0551 18.7409 28.8154 18.8002 28.256L19.5155 21.5114Z" fill="#0671E0"/>
			<defs>
				<filter id="filter0_i_43338_45673" x="0.666992" y="0" width="42" height="44.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dx="4" dy="7"/>
					<feGaussianBlur stdDeviation="3.25"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43338_45673"/>
				</filter>
			</defs>
		</svg>

	)
}
