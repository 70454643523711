import React from "react";

const CalendarHeadIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
      <path
        d="M11.2376 17.3189L9.48482 17.4042C9.31062 17.4126 9.22352 17.4169 9.16862 17.3643C9.11371 17.3118 9.11371 17.2242 9.11371 17.049V14.062C9.11371 13.9222 9.11371 13.8524 9.0735 13.8032C9.03329 13.754 8.96504 13.7404 8.82856 13.7132L8.70303 13.6882C8.38339 13.6246 8.22357 13.5928 8.19645 13.4832C8.16932 13.3737 8.29567 13.2704 8.54836 13.0637L11.8691 10.348C12.1638 10.1069 12.3112 9.98643 12.4162 10.0458C12.5213 10.1051 12.4952 10.2942 12.4432 10.6724L11.5711 17.0124C11.5519 17.1522 11.5423 17.2221 11.4951 17.2654C11.448 17.3086 11.3779 17.3121 11.2376 17.3189Z"
        fill="#0671E0"
      />
      <path
        d="M3.52795 23.942L15.1628 21.6914C16.8011 21.3745 17.6203 21.2161 17.6015 21.0523C17.5827 20.8886 16.7492 20.9214 15.082 20.987L2.92334 21.4656C2.75054 21.4724 2.66413 21.4758 2.60982 21.4233C2.5555 21.3709 2.5555 21.284 2.5555 21.1103V6.1122C2.5555 5.9306 2.5555 5.8398 2.61296 5.78695C2.67043 5.7341 2.76047 5.74207 2.94057 5.75802L18.2302 7.11207C18.3777 7.12514 18.4515 7.13168 18.4976 7.18016C18.5436 7.22863 18.5467 7.30298 18.5527 7.45166L18.6209 9.11953C18.6379 9.53387 18.6463 9.74104 18.7653 9.77835C18.8843 9.81566 19.0086 9.65012 19.2572 9.31905L19.809 8.58422C19.8428 8.53926 19.8597 8.51677 19.8688 8.49063C19.8779 8.46449 19.8787 8.43632 19.8802 8.38004L19.9986 4.12368C20.0473 2.10492 18.6413 1.60023 17.9322 1.60023L2.5555 0C0.51338 0.049238 0.00285632 1.49766 0.00286 2.21571V20.8031C-0.094384 23.8558 2.3124 24.1677 3.52795 23.942Z"
        fill="#0671E0"
      />
      <path
        d="M3.52795 23.942L10.57 22.5798C13.8467 21.946 15.4851 21.6291 15.4475 21.3016C15.4099 20.9741 13.7428 21.0397 10.4084 21.171L3.29118 21.4511C2.94557 21.4647 2.77276 21.4715 2.66413 21.3666C2.5555 21.2617 2.5555 21.088 2.5555 20.7406V6.50048C2.5555 6.13728 2.5555 5.95568 2.67043 5.84998C2.78535 5.74427 2.96545 5.76022 3.32564 5.79212L18.1937 7.10885C18.2253 7.11164 18.241 7.11304 18.2548 7.11318C18.4132 7.11478 18.5534 7.0105 18.598 6.85786C18.6019 6.84457 18.6052 6.82902 18.6117 6.7979L18.8564 5.63275C18.8775 5.53231 19.0184 5.52732 19.0465 5.62603C19.0709 5.71199 19.1876 5.72332 19.228 5.64365L19.9262 4.26665C19.9629 4.19422 19.9812 4.158 19.991 4.11636L19.9916 4.11398C20.0011 4.07227 20.0005 4.03575 19.9994 3.96274C19.9715 2.07792 18.622 1.60023 17.9322 1.60023L2.5555 0C0.51338 0.049238 0.00285632 1.49766 0.00286 2.21571V20.8031C-0.094384 23.8558 2.3124 24.1677 3.52795 23.942Z"
        fill="#0671E0"
      />
    </svg>
  );
};

export default CalendarHeadIcon;
