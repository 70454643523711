const content = (requiredPrices,product,selectedPlanType) => {
	let tempRes = {
		//Calendar features
		calendar : {
			"P0" : [
				{
					displayText : "Get 30 event credits",
					tooltipText : "Lifetime limit - does not renew"
				},
				{
					displayText : "Schedule sessions on Calendar",
					tooltipText : "Only sessions allowed to be scheduled"
				},
				{
					displayText : "Set your own availability",
					tooltipText : null
				},
				{
					displayText : "Add your time-offs",
					tooltipText : null
				},
				{
					displayText : "Generate session reports",
					tooltipText : null
				}
			],
			"P1" : [
				{
					displayText : `${(product === "calendar" && requiredPrices?.P1) ? requiredPrices?.P1.limit : "" } events per ${selectedPlanType === "annual" ? "year" : "quarter"}`,
					tooltipText : "The limit resets every billing cycle"
				},
				{
					displayText : "Sessions + Consultations",
					tooltipText : null
				},
				{
					displayText : "Set everyone's availability",
					tooltipText : "Any admin can select a user and set their available hours to their Calendar"
				},
				{
					displayText : "Use advanced filters",
					tooltipText : null
				},
				{
					displayText : "Unlock detailed reports",
					tooltipText : null
				}
			],
			"P2" :  [
				{
					"displayText": "No limit on events",
					"tooltipText": "Schedule as many events on your calendar as you want without restrictions"
				},
				{
					"displayText": "All event types",
					"tooltipText": "Sessions, Consultations, Internal meetings, Booked meetings, and Office hours"
				},
				{
					"displayText": "Advanced session reconciliation",
					"tooltipText": "Use session tags, notes, reminders, updates, and many more tools for recording your session details"
				},
				{
					"displayText": "Nuanced reports and filters",
					"tooltipText": "Generate ALL types of reports for every single event type that has ever been scheduled on your Evallo calendar - with exporting feature!"
				},
				{
					"displayText": "Assign multiple administrators",
					"tooltipText": "Invite your team to manage staff calendars and set up the permissions"
				}
			]
		},
		//Invoicing features
		invoicing : {
			"P0" :[
				{
					"displayText": "Unlimited invoices",
					"tooltipText": "Generate as many invoices as you want"
				},
				{
					"displayText": "Payments via platform",
					"tooltipText": "You are mandatorily required to collect all payments for generated invoices via Evallo - manual payments are not allowed"
				},
				{
					"displayText": "Client portals and dashboards",
					"tooltipText": "All your clients get their own login access"
				},
				{
					"displayText": "Automated and manual invoicing",
					"tooltipText": "Manual invoicing includes Packages and Single-Charges. Automated invoices include Periodic, Pre-session and Post-session."
				},
				{
					"displayText": "Set family-level defaults",
					"tooltipText": "Parent and child accounts are merged into families, for which you can set certain defaults and view transaction history."
				}
			],
			"P1" : [
				{
					"displayText": "Unlimited invoices",
					"tooltipText": "Generate as many invoices as you want"
				},
				{
					"displayText": "Auto-charge customers",
					"tooltipText": "As long as your customers give explicit consent to charge their credit card, you can use their saved cards on file to charge for services"
				},
				{
					"displayText": "Add payment links",
					"tooltipText": "You can generate payment links for your invoices that can be shared with a customer manually"
				},
				{
					"displayText": "Bulk invoice generation",
					"tooltipText": "Select families in bulk, click on 'generate invoices' and voila! Every session the families had within the selected date range will be added to an invoice!"
				},
				{
					"displayText": "Export data",
					"tooltipText": "Export data for your invoicing system in a spreadsheet for your records"
				}
			],
			"P2" : [
				{
					"displayText": "Unlock everything!",
					"tooltipText": null
				},
				{
					"displayText": "Generate detailed reports",
					"tooltipText": "Access detailed analytics and reports on invoicing trends, payment collection, and client behavior to improve financial forecasting."
				},
				{
					"displayText": "Add credits in hours or $$",
					"tooltipText": "Credit balance can be added in two forms - dollar amount (e.g. giving $300 credit per referral that your customer brings you) or service hours (e.g. giving 2 hours of free SAT prep). Credit balance is useful for incentivizing your customers to bring more referrals for your business and also avoid revenue loss from refunds due to subjective dissatisfaction towards a service."
				},
				{
					"displayText": "Split & schedule invoices",
					"tooltipText": "Divide a single invoice across multiple dates and payment methods. Perfect for shared services or family accounts."
				},
				{
					"displayText": "Add remarks & notes",
					"tooltipText": "Include remarks or special instructions for clients directly on the invoice. Useful for adding context or clarifying charges."
				}
			]
		},
		//Assignments features
		assignments : {
			"P0" : [
				{
					"displayText": "20 Assignments",
					"tooltipText": "Lifetime limit - does not renew"
				},
				{
					"displayText": "2 full-length DSAT®",
					"tooltipText": "Based on your subscription plan, you are entitled to a specific number of digital SAT® practice tests. Additional simulations can be purchased or unlocked through higher plans"
				},
				{
					"displayText": "Official ACT® answer keys",
					"tooltipText": "Automates the scoring of ACT® tests, reducing tutor workload and ensuring accurate results aligned with official scoring guidelines."
				},
				{
					"displayText": "Offline (paper-based) tests",
					"tooltipText": "This feature enables you to design and manage offline, paper-based assignments. Perfect for schools or tutoring centers that prefer traditional methods."
				},
				{
					"displayText": "2 Custom Assignments",
					"tooltipText": "Create assignments tailored to your students' needs, including custom question types and difficulty levels."
				}
			],
			"P1" : [
				{
					"displayText": `${(product === "assignments" && requiredPrices?.P1) ? requiredPrices?.P1.limit : ""} assignments per ${selectedPlanType === "annual" ? "year" : "quarter"}`,
					"tooltipText": "The limit resets every billing cycle"
				},
				{
					"displayText": "5 full-length DSAT®",
					"tooltipText": "Based on your subscription plan, you are entitled to a specific number of digital SAT® practice tests. Additional simulations can be purchased or unlocked through higher plans"
				},
				{
					"displayText": "15 Custom Assignments",
					"tooltipText": "Create assignments tailored to your students' needs, including custom question types and difficulty levels."
				},
				{
					"displayText": "Written explanations",
					"tooltipText": "Provide written explanations for each question to help students understand concepts and correct mistakes effectively."
				},
				{
					"displayText": "Advanced filters",
					"tooltipText": "Use advanced filters to locate questions by topic, difficulty, or type. Simplifies assignment creation and saves time."
				}
			],
			"P2" : [
				{
					"displayText": `${(product === "assignments" && requiredPrices?.P2) ? requiredPrices?.P2.limit === -1 ? "Unlimited" : requiredPrices?.P2.limit : ""} assignments per ${selectedPlanType === "annual" ? "year" : "quarter"}`,
					"tooltipText": "The limit resets every billing cycle"
				},
				{
					"displayText": "15 full-length DSAT®",
					"tooltipText": "Based on your subscription plan, you are entitled to a specific number of digital SAT® practice tests. Additional simulations can be purchased or unlocked through higher plans."
				},
				{
					"displayText": "40 Custom assignments",
					"tooltipText": "Create assignments tailored to your students' needs, including custom question types and difficulty levels."
				},
				{
					"displayText": "Access to question bank",
					"tooltipText": "Access 10,000+ questions to be assigned to your students via drills and practice tests!"
				},
				{
					"displayText": "Unlock ALL features!",
					"tooltipText": "Unlock all features included with PrepWork"
				}
			]
		}

	}

	return tempRes[product]

}


export {
	content
}
