import { Tooltip } from "@mui/material"
import styles from "./FeatureTable.module.css"
import AllowedIcon from "../Icons/AllowedIcon"
import { IntegrationsAd } from "../Icons/CalendarIntIcons"
import InfoMoreIcon from "../Icons/InfoMoreIcon"
import NoAllowedIcon from "../Icons/NoAllowedIcon"
import ArmadaMbIcon from "../PackageTierIcons/ArmadaMbIcon"
import AramadaRoundedIcon from "../PackageTierIcons/ArmadaRoundedIcon"
import CrewMbIcon from "../PackageTierIcons/CrewMbIcon"
import CrewRoundedIcon from "../PackageTierIcons/CrewRoundedIcon"
import ExplorerMbIcon from "../PackageTierIcons/ExplorerMbIcon"
import ExplorerRoundedIcon from "../PackageTierIcons/ExplorerRoundedIcon"
import { useState } from "react"

const TIER_NAME_MAPPING = {
  individual: {
    P0: "Explorer",
    P1: "Builder",
    P2: "Achiever",
  },
  business: {
    P0: "Explorer",
    P1: "Crew",
    P2: "Armada",
  },
}

function TableHeaderBG() {
  return <div className={styles.tableHeader}></div>
}

function TableHeader({ header, teamSizeTierSelected }) {
  return (
    <>
      <div className={`${styles.headerText}`}>{header}</div>
      <div className={`${styles.iconContainer} ${styles.explorer}`}>
        <ExplorerRoundedIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P0"]}</span>
      </div>
      <div className={`${styles.iconContainer} ${styles.crew}`}>
        <CrewRoundedIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P1"]}</span>
      </div>
      <div className={`${styles.iconContainer} ${styles.armada}`}>
        <AramadaRoundedIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P2"]}</span>
      </div>
    </>
  )
}

const FEATURE_NAME_ICON_MAPPING = {
  _allowed: <AllowedIcon width="17" height="17" />,
  not_allowed: <NoAllowedIcon width="17" height="17" />,
}

const FEATURE_NAME_ICON_MAPPING_MOBILE = {
  _allowed: <AllowedIcon height="15" width="15" />,
  not_allowed: <NoAllowedIcon height="15" width="15" />,
}

const tooltipProps = {
  placement: "top",
  slotProps: {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -14],
          },
        },
      ],
    },
  },
  componentsProps: {
    tooltip: {
      sx: {
        bgcolor: "white",
        cursor: "pointer",
        padding: "0px",
        margin: "0px",
        borderRadius: "12px",
        fontFamily: "lexend",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        color: "#515C7B",
        padding: "12px",
        boxShadow: "0px 4px 24px 0px #11167D26",
      },
    },
  },
}

function TableItem({ featureTitle, featureDescription, featureTooltipText, infoTooltip, featureContent, idx, requiredPrices, limitsData }) {
  return (
    <>
      <div className={styles.headerTextNew} style={{ gridRow: `${idx + 3}/${idx + 4}` }}>
        <div className={styles.titleWrapperNew}>
          <h4 className={styles.featureTitleText}>{featureTitle}</h4>
          <Tooltip title={infoTooltip}>
            <div className={styles.iconWrapperNew}>
              <InfoMoreIcon />
            </div>
          </Tooltip>
        </div>
        <span className={styles.featureDescriptionNew}>{featureDescription}</span>
      </div>

      <div className={`${styles.featureContentNew}`} style={{ gridRow: `${idx + 3}/${idx + 4}` }}>
        {!requiredPrices && featureContent[0].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING[featureContent[0]] : featureContent[0].includes("dyn_limit") ? limitsData["P0"].limit : featureContent[0]}
      </div>

      <div className={`${styles.featureContentNew}`} style={{ gridRow: `${idx + 3}/${idx + 4}` }}>
        {requiredPrices ? <>${(requiredPrices["P1"].unit_amount / 100).toFixed(2)}</> : featureContent[1].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING[featureContent[1]] : featureContent[1].includes("dyn_limit") ? limitsData["P1"].limit : featureContent[1]}
      </div>

      <div className={`${styles.featureContentNew}`} style={{ gridRow: `${idx + 3}/${idx + 4}` }}>
        {featureContent[2]?.includes("Logo") ? <IntegrationsAd /> : requiredPrices ? <>${(requiredPrices["P2"].unit_amount / 100).toFixed(2)}</> : featureContent[2].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING[featureContent[2]] : featureContent[2].includes("dyn_limit") ? limitsData["P2"].limit : featureContent[2]}
      </div>
    </>
  )
}

function MobileTableItem({ selectedTab, featureContent, idx, requiredPrices, limitsData }) {
  return (
    <>
      {selectedTab === 0 && <div className={styles.mobileTableContent}>{featureContent[0].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING_MOBILE[featureContent[0]] : featureContent[0].includes("dyn_limit") ? limitsData["P0"].limit : featureContent[0]}</div>}
      {selectedTab === 1 && <div className={styles.mobileTableContent}>{requiredPrices ? <>${(requiredPrices["P1"].unit_amount / 100).toFixed(2)}</> : featureContent[1].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING_MOBILE[featureContent[1]] : featureContent[1].includes("dyn_limit") ? limitsData["P1"].limit : featureContent[1]}</div>}
      {selectedTab === 2 && <div className={styles.mobileTableContent}>{requiredPrices ? <>${(requiredPrices["P2"].unit_amount / 100).toFixed(2)}</> : featureContent[2].includes("_allowed") ? FEATURE_NAME_ICON_MAPPING_MOBILE[featureContent[2]] : featureContent[2].includes("dyn_limit") ? limitsData["P2"].limit : featureContent[2]}</div>}
    </>
  )
}

function VerticalLine({ style }) {
  return <div className={styles.separatorLineNew} style={style} />
}

function MobileHeader({ teamSizeTierSelected, selectedTab, setSelectedTab }) {
  return (
    <div className={styles.mobileColumnHeader}>
      <div style={{ color: selectedTab === 0 && "#fff", backgroundColor: selectedTab === 0 && "#1476DB" }} onClick={() => setSelectedTab(0)} className={styles.mobileColumnHeaderItem}>
        <ExplorerMbIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P0"]}</span>
      </div>
      <div style={{ color: selectedTab === 1 && "#fff", backgroundColor: selectedTab === 1 && "#1476DB" }} onClick={() => setSelectedTab(1)} className={styles.mobileColumnHeaderItem}>
        <CrewMbIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P1"]}</span>
      </div>
      <div style={{ color: selectedTab === 2 && "#fff", backgroundColor: selectedTab === 2 && "#1476DB" }} onClick={() => setSelectedTab(2)} className={styles.mobileColumnHeaderItem}>
        <ArmadaMbIcon />
        <span>{TIER_NAME_MAPPING[teamSizeTierSelected.startsWith("I") ? "individual" : "business"]["P2"]}</span>
      </div>
    </div>
  )
}

function MobileTable({ data, selectedTab, requiredPrices }) {
  return (
    <div className={styles.mobileTableContainer}>
      {data?.map((val, idx) => {
        return (
          <>
            <div className={styles.mobileTableItem} key={idx}>
              <div className={styles.mobileTableFirstDiv}>
                <span className={styles.mobileTableFirstTitle}>{val?.featureTitle}</span>
                <span className={styles.mobileTableFirstDescription}>{val?.featureDescription}</span>
              </div>
              <MobileTableItem {...{ selectedTab, ...val, idx }} {...(idx === 0 && { requiredPrices })} {...{ limitsData: requiredPrices }} />
            </div>
            {data.length - 1 !== idx && <hr className={styles.mobileTableSeparatorLine} />}
          </>
        )
      })}
    </div>
  )
}

const FeaturesTable = ({ data, header, requiredPrices, renderOnNull = false, teamSizeTierSelected }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  if (requiredPrices === undefined && renderOnNull === false) {
    return <></>
  }
  return (
    <>
      {/* Mobile Grid */}
      <div className={styles.mobileGridContainer}>
        <MobileHeader {...{ teamSizeTierSelected, selectedTab, setSelectedTab }} />
        <MobileTable {...{ data, selectedTab, requiredPrices }} />
      </div>
      {/* <div className={styles.mobileGridContainer} style={{ gridTemplateRows: `50px repeat(${data?.length},42px)` }}>
        <MobileFirstColumn data={data} dataLength={data?.length} header={header} />
        <MobileHeader header={header} teamSizeTierSelected={teamSizeTierSelected} />
        {data?.map((val, idx) => (
          <MobileTableItem {...val} idx={idx} {...(idx === 0 && { requiredPrices })} {...{ limitsData: requiredPrices }} />
        ))}
      </div> */}

      {/* Desktop Grid */}
      <div className={styles.desktopGridContainer} style={{ gridTemplateRows: `auto auto repeat(${data?.length},auto)` }}>
        <TableHeaderBG />
        <div className={styles.desktopStickyDivider}>
          <div className={styles.desktopStickyDividerDiv} />
        </div>
        <TableHeader header={header} teamSizeTierSelected={teamSizeTierSelected} />
        {data?.map((val, idx) => (
          <TableItem {...val} idx={idx} {...(idx === 0 && { requiredPrices })} {...{ limitsData: requiredPrices }} />
        ))}
        <VerticalLine style={{ gridRow: "1 / -1", gridColumn: "2 / 3", zIndex: 100 }} />
        <VerticalLine style={{ gridRow: "1 / -1", gridColumn: "4 / 5", zIndex: 100 }} />
        <VerticalLine style={{ gridRow: "1 / -1", gridColumn: "6 / 7", zIndex: 100 }} />
      </div>
    </>
  )
}

export default FeaturesTable
