export default function CrewIcon(props){
	return(
		<svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width="44" height="43" rx="6" fill="#EEF6FF"/>
			<g filter="url(#filter0_f_44000_10123)">
				<path d="M21.5422 25.6255H15.3498C14.8986 25.6255 14.6144 25.1132 14.8352 24.6982L22.1852 10.8835C22.4994 10.2929 23.3553 10.5816 23.2865 11.255L22.4568 19.3743H28.6493C29.1004 19.3743 29.3847 19.8866 29.1639 20.3016L21.8139 34.1162C21.4997 34.7068 20.6438 34.4182 20.7126 33.7448L21.5422 25.6255Z" fill="#0D96F2"/>
			</g>
			<path d="M21.5422 23.6255H15.3498C14.8986 23.6255 14.6144 23.1132 14.8352 22.6982L22.1852 8.88354C22.4994 8.29291 23.3553 8.58161 23.2865 9.255L22.4568 17.3743H28.6493C29.1004 17.3743 29.3847 17.8866 29.1639 18.3016L21.8139 32.1162C21.4997 32.7068 20.6438 32.4182 20.7126 31.7448L21.5422 23.6255Z" fill="#0D96F2"/>
			<defs>
				<filter id="filter0_f_44000_10123" x="8.35879" y="4.16396" width="27.2814" height="36.6721" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="3.2" result="effect1_foregroundBlur_44000_10123"/>
				</filter>
			</defs>
		</svg>
	)
}
