import React, { useState } from 'react';
import styles from './SavingsCalculator.module.css';  // Using the module style import
import savingsGif from '../savings.gif';

const SavingsCalculator = () => {
  const [discount, setDiscount] = useState(9);
  const [revenue, setRevenue] = useState();
  
  const handleInputChange = (text) => {
    setRevenue(text);
  };
  
  const formatAmount = (amount) => {
    // Convert the amount to a number
    const numberAmount = parseFloat(amount);

    // Check if the amount is already an integer or has decimals
    if (Number.isInteger(numberAmount)) {
      return amount; // Return the amount as is if it's an integer
    }

    // Round the number to two decimal places if it has decimals
    const roundedAmount = numberAmount.toFixed(2);

    // Return the formatted amount
    return roundedAmount;
  };

  const calculateDiscount = () => {
    const transactions = (revenue / 100);
    const competitiorCharges = (transactions * 2.9) + (transactions * 0.3) + transactions;
    const evalloCharges = transactions * 2.9;
    const discount = (competitiorCharges - evalloCharges);
    console.log({ discount });
    setDiscount(discount);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainerSavings}>
        <div className={styles.savingsContent}>
          <div className={styles.savingsText}>
            <div className={styles.mainHeading}>
              <div className={styles.saveMore}>Save more&nbsp;</div>
              <div style={{ color: '#333' }}>with Evallo</div>
            </div>
          </div>
          <div className={styles.savingsInfo}>
            Compare Evallo against Stripe, Square, and QuickBooks—and put those savings straight back into your business.
          </div>
        </div>
        <div className={styles.savingsCalculator}>
          <div className={styles.savingsInputArea}>
            <input
              className={`${styles.savingsInput} ${styles.inputStyles}`}
              type="number"
              placeholder="Enter Revenue"
              value={revenue}
              onChange={(e) => { handleInputChange(e.target.value); }}
            />
            <button className={styles.savingsButton} onClick={calculateDiscount}>Calculate Savings</button>
          </div>
          <div className={styles.savingsDisplayArea}>
            <img src={savingsGif} alt="savings" className={styles.savingsGif} />
            <div className={styles.savingsResult}>
              <div className={styles.savingsText}>Save Up to&nbsp;</div>
              <div className={styles.savingsAmount}>
                ${formatAmount(discount)}
              </div>
              <div className={styles.savingsText}>&nbsp;with Evallo</div>
            </div>
          </div>
        </div>
        <div className={styles.comparisonContainer}>
          <div className={styles.evalloCharge}>
            <div className={styles.evalloText}>Evallo</div>
            <div className={styles.evalloSubText}>2.9% Per Transaction</div>
          </div>
          <div className={styles.curveContainer}></div>
          <div className={styles.competitors}>
            <div className={styles.competitorInfo}>
              <div className={styles.competitorName}>Competitor X</div>
              <div className={styles.competitorDetails}>2.9% + $.30 Per Transaction + 1% of Revenue</div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.competitorInfo}>
              <div className={styles.competitorName}>Quickbook</div>
              <div className={styles.competitorDetails}>2.99% Per Transaction</div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.competitorInfo}>
              <div className={styles.competitorName}>Square</div>
              <div className={styles.competitorDetails}>3.3% + $.30 Per Transaction</div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.competitorInfo}>
              <div className={styles.competitorName}>Stripe</div>
              <div className={styles.competitorDetails}>2.9%+ $.30 Per Transaction</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsCalculator;
