import React from "react";
import styles from "./SlideOne.module.css";

const SlideOne = ({
  heading,
  description,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonUrl,
  imageSrc,
  imageAlt,
  setLeadFormTrigger,
}) => {
  const highlightIndexes=[6,7,8]
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div className={styles.headingWrapper}>
            <h1 className={styles.heading}>
              {heading.split(" ").map((word, index) => (
                <span
                  key={index}
                  className={
                    highlightIndexes.includes(index)
                      ? styles.blueText
                      : styles.blackText
                  }
                >
                  {word}&nbsp;
                </span>
              ))}
            </h1>
          </div>
          <p className={styles.description}>{description}</p>
          <div className={styles.buttonGroup}>
            <button
              onClick={() => setLeadFormTrigger(true)}
              className={styles.primaryButton}
            >
              <span className={styles.buttonText}>{primaryButtonText}</span>
            </button>
            <a href={secondaryButtonUrl} className={styles.secondaryButton}>
              <span className={styles.buttonText}>{secondaryButtonText}</span>
            </a>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <img src={imageSrc} alt={imageAlt} className={styles.image} />
        </div>
      </div>
    </div>
  );
};

export default SlideOne;
