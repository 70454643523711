import React from 'react';
import './BusinessStats.css';
import Carousel from './Carousel';

const BusinessStats = ({setLeadFormTrigger}) => {
  return (
    <div className="combined-container">
      <div className="business-stats-container">
        <div className="stats-wrapper">
          <div className="stat-item">
            <div className="stat-number">
              1000+
            </div>
            <div className="stat-label">
              GLOBAL PROVIDER
            </div>
          </div>
          
          <div className="stat-item">
            <div className="stat-number">
              200+
            </div>
            <div className="stat-label">
              BUSINESSES ONBOARDED
            </div>
          </div>
        </div>
      </div>
      
      <div className="discover-card-container">
        <div className="card-background"></div>
        <div className="card-foreground">
          <h2 className="card-title"><span className="blue-text">Discover Seamless</span> Scheduling with Evallo</h2>
          <div className="ellipses-group">
            <div className="ellipse"></div>
            <div className="ellipse"></div>
            <div className="ellipse"></div>
          </div>
          <div className="button-container">
            <button className="button" onClick={() => setLeadFormTrigger(true)}>
              <span className="button-text">Book Demo</span>
              <span className="button-arrow"></span>
            </button>
          </div>
        </div>
      </div>
      <Carousel />
    </div>
  );
};

export default BusinessStats;



