const CALENDAR_DATA = [
  {
    "featureTitle": "Pricing",
    "featureDescription": "Our pricing structure is designed to suit your team size and usage",
    "infoTooltip": "The pricing shown here is dependent on your team size. If you change your team size, these prices will change accordingly.",
    "featureContent": [
      "Lifetime Free",
      "{plan specific}",
      "{plan specific}"
    ]
  },
  {
    "featureTitle": "Event Credits",
    "featureDescription": "Each event counts for one credit",
    "infoTooltip": "Event is a generalized term we use to describe various types of meetings that can be scheduled on Evallo's Calendar: sessions, consultations, internal meetings, booked events, and office hours",
    "featureContent": [
      "30",
      "dyn_limit",
      "Unlimited"
    ]
  },
  {
    "featureTitle": "Event Types",
    "featureDescription": "Categorize your events based on the agenda and the attendees",
    "infoTooltip": "\"Sessions - scheduled for providing actual service. Consultations - scheduled for initial consulting and planning with clients. Booked Events - directly blocked on your calendar using the booking link. Internal Meetings - for internal team meetings and calls. Office Hours - to record administrative work beyond actual service hours or consultations.\"",
    "featureContent": [
      "Sessions",
      "Sessions and Consultations",
      "Sessions, Consultations, Internal meetings, Booked meetings, and Office hours"
    ]
  },
  {
    "featureTitle": "Booking Links",
    "featureDescription": "Share external booking links to directly block your calendar as per your availability",
    "infoTooltip": "Every internal user you have added to your team will get a unique booking link that can be shared with external users to directly book a meeting on the respective user's calendar. You can also paste it on your website for capturing leads!",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Schedule Mapper",
    "featureDescription": "Easily map clients with service providers based on their mutual availabilities",
    "infoTooltip": "Schedule Mapper allows you to select a time block over a date range to map clients and team members with each other based on their available hours",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Time Blocks",
    "featureDescription": "Visualize the availability of your staff via color-coded time blocks",
    "infoTooltip": "Time blocks can be used by admins to match a service provider with a client by selecting their names and then overlapping their available time ranges on the weekly calendar view. Depending upon your plan, you get to select a certain number of users to see their overlapping availabilities.",
    "featureContent": [
      "1 Selection",
      "2 Selections",
      "5 Selections"
    ]
  },
  {
    "featureTitle": "Reports & Analytics",
    "featureDescription": "Advanced analytics allows 100+ possible permutations to play around with!",
    "infoTooltip": "Analytics includes nuanced filters that can pull data the way you want to see it - be it on the platform or in an exportable sheet. Some of these include event types, services, user types, groups, session statuses, hourly rates, and location!",
    "featureContent": [
      "Limited",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Integrations",
    "featureDescription": "G-Cal, G-Meet, Zoom, and MS Teams",
    "infoTooltip": "Integrations allow a 2-way sync between your Evallo account and available 3rd party platforms. Currently, we support Google Calendar, Google Meet, Zoom and MS Teams for syncing your calendar events.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "Logos of G-Cal, G-Meet, Zoom, Outlook"
    ]
  },
  {
    "featureTitle": "Availability",
    "featureDescription": "Add your working hours",
    "infoTooltip": "Availability can be used to ensure that you are not booked beyond your working hours. It is used for booking links as well as event requests made by your clients. Super useful if you like a routine!",
    "featureContent": [
      "Limited",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Event Requests",
    "featureDescription": "Clients can request events directly from their portal",
    "infoTooltip": "Your clients and customers get their own login portal and calendar access. This allows them to request events directly on your calendar, which then wait for an approval from you before showing up on your calendar.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Time Offs",
    "featureDescription": "Block certain hours/days/weeks from your calendar to avoid conflicts",
    "infoTooltip": "Time Offs allow you and your team to record days and times when they are not available. This is different than availability as it specifically marks off time blocks when the user is not able to provide any services.",
    "featureContent": [
      "Limited",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Multiple Calendar Views",
    "featureDescription": "Weekly, monthly and yearly view of the Calendar",
    "infoTooltip": "Switch between weekly, monthly and yearly views as per your preference.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Advanced Filters",
    "featureDescription": "Only see what you want to see with 20+ filters",
    "infoTooltip": "Advanced filters allow you to find the data you are looking for on the Calendar with pin-point accuracy. It includes nuanced elements such as usertypes, hourly rates, service, location and more!",
    "featureContent": [
      "Limited",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Session Tags",
    "featureDescription": "No time for notes between meetings? Use tags instead for quick reference!",
    "infoTooltip": "If you are tired of typing notes at the end of a session, or maybe you just don't have the time, you can quickly add your own headings and associated tags to quickly record some details about a session as per your preference.",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Notifications",
    "featureDescription": "Choose when and where you want to trigger event reminders automatically",
    "infoTooltip": "You can control whom to send the email reminders about scheduled events and when to send them this email.",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Group Events",
    "featureDescription": "Create groups and invite them all for a session!",
    "infoTooltip": "Add groups of students to a session for group classes and meetings",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Event Notes",
    "featureDescription": "Add detailed notes after every meeting and choose whom you want to share them with",
    "infoTooltip": "Event notes can be added as part of any event (session, consultation, internal meeting, etc.) and you can also choose who to share these notes with - internally, publicly, or specific notes for specific users!",
    "featureContent": [
      "Internal Only",
      "Internal + Shared",
      "Personalized"
    ]
  },
  {
    "featureTitle": "Recurring Events",
    "featureDescription": "Repeat events at a certain frequency of your choice",
    "infoTooltip": "Schedule events at a set frequency - weekly, bi-weekly, monthly, quarterly, semi-annually and annually. Save hours on countless scheduling headaches with this!",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Calendar Permissions",
    "featureDescription": "Set customized scheduling policies as per your business preferences",
    "infoTooltip": "Customizable calendar policies allow you to control your team's behavior as well as implement policies such as cancellation, no-shows, tutor referrals, etc. to a great effect!",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Set Calendar Defaults",
    "featureDescription": "Save default settings so you can schedule events with a single click!",
    "infoTooltip": "Save event default settings so that you can quickly schedule tons of sessions without having to adjust some information again and again!",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Multiple Locations",
    "featureDescription": "Choose from multiple addresses where to schedule the event",
    "infoTooltip": "Add as many locations as you want",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  }
]

const ASN_DATA = [
  {
    "featureTitle": "Pricing",
    "featureDescription": "Tailored pricing for prep materials",
    "infoTooltip": "The pricing shown here is dependent on your team size. The access to prep materials depends on your subscription plan. Plans may include additional features such as enhanced analytics, question banks, or progress tracking tools.",
    "featureContent": [
      "Lifetime Free",
      "{plan specific}",
      "{plan specific}"
    ]
  },
  {
    "featureTitle": "Assignment Credits",
    "featureDescription": "Allocate and track assignments",
    "infoTooltip": "A fixed number of assignment credits is provided based on your plan. Each credit allows one assignment to be issued, ensuring equitable distribution of resources among users.",
    "featureContent": [
      "20",
      "dyn_limit",
      "dyn_limit"
    ]
  },
  {
    "featureTitle": "DSAT Simulation",
    "featureDescription": "Practice digital SAT® exams",
    "infoTooltip": "Provides students with a realistic digital SAT® simulation. Designed to mimic the official College Board interface, helping students gain familiarity and reduce test-day anxiety.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "DSATs Included",
    "featureDescription": "Number of SAT® simulations provided",
    "infoTooltip": "Based on your subscription plan, you are entitled to a specific number of digital SAT® practice tests. Additional simulations can be purchased or unlocked through higher plans.",
    "featureContent": [
      "2",
      "5",
      "15"
    ]
  },
  {
    "featureTitle": "Offline Assignments",
    "featureDescription": "Prepare and distribute paper assignments",
    "infoTooltip": "This feature enables you to design and manage offline, paper-based assignments. Perfect for schools or tutoring centers that prefer traditional methods.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Automated ACT scoring",
    "featureDescription": "Grade ACT tests automatically",
    "infoTooltip": "Automates the scoring of ACT tests, reducing tutor workload and ensuring accurate results aligned with official scoring guidelines.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Custom Assignments",
    "featureDescription": "Design personalized assignments",
    "infoTooltip": "Create assignments tailored to your students' needs, including custom question types and difficulty levels.",
    "featureContent": [
      "2",
      "15",
      "40"
    ]
  },
  {
    "featureTitle": "Question Difficulty",
    "featureDescription": "Categorize questions by difficulty",
    "infoTooltip": "Assign and filter questions based on difficulty levels (easy, medium, hard). Helps in curating assignments to match students' proficiency.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Written Explanations",
    "featureDescription": "Add detailed explanations to answers",
    "infoTooltip": "Provide written explanations for each question to help students understand concepts and correct mistakes effectively.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Group Assignment",
    "featureDescription": "Assign tasks to groups of students",
    "infoTooltip": "Manage group-based prepwork, enabling collaborative problem-solving and collective progress tracking.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Due Date",
    "featureDescription": "Set deadlines for assignments",
    "infoTooltip": "Specify submission deadlines for assignments to ensure timely completion and track overdue submissions.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Timer Control",
    "featureDescription": "Enable timed assignments",
    "infoTooltip": "Add time limits to assignments or tests to simulate real exam conditions, improving students’ time management skills.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Advanced Filters",
    "featureDescription": "Filter questions for precision",
    "infoTooltip": "Use advanced filters to locate questions by topic, difficulty, or type. Simplifies assignment creation and saves time.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Instant Score Report",
    "featureDescription": "Immediate feedback on tests",
    "infoTooltip": "Automatically generate score reports as soon as students submit assignments. Includes performance breakdowns and improvement suggestions.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Time Taken per Question",
    "featureDescription": "Track time spent on each question",
    "infoTooltip": "Analyze how much time students spend on individual questions to identify problem areas or efficiency gaps.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Video Explanations",
    "featureDescription": "Provide video solutions",
    "infoTooltip": "Include video tutorials or explanations for selected questions. Useful for visual learners and detailed walkthroughs.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Score Progression",
    "featureDescription": "Monitor performance over time",
    "infoTooltip": "Track score improvements across multiple assignments or tests, helping identify long-term growth trends.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Time Taken per Concept",
    "featureDescription": "Analyze concept-specific timing",
    "infoTooltip": "Evaluate the time students spend on specific concepts. Reveals areas that need more practice or focus.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Conceptual Accuracy Chart",
    "featureDescription": "Visualize accuracy by concept",
    "infoTooltip": "Provides a detailed chart of accuracy rates for various concepts. Helps tutors pinpoint and address weak areas.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  }
]

const INV_DATA = [
  {
    "featureTitle": "Pricing",
    "featureDescription": "Our pricing structure is designed to suit your team size and usage",
    "infoTooltip": "The pricing shown here is dependent on your team size. If you change your team size, these prices will change accordingly.",
    "featureContent": [
      "Lifetime Free",
      "{plan specific}",
      "{plan specific}"
    ]
  },
  {
    "featureTitle": "Number of Invoices",
    "featureDescription": "Generate invoices without any restriction!",
    "infoTooltip": "Refers to the number of invoices you can generate on the platform. Each individual invoice has a unique ID and comes in PDF as well as web-based format, directly accessible for you and your clients.",
    "featureContent": [
      "Unlimited",
      "Unlimited",
      "Unlimited"
    ]
  },
  {
    "featureTitle": "Convenience Fee (card)",
    "featureDescription": "Fee charged on each transaction that can be absorbed by you or transferred to the buyer.",
    "infoTooltip": "Each transaction on the platform comes with an additional Convenience Fee that is levied on the business before a payout is made. This fee covers various costs, such as card processing fee, interchange fee, payment processor fees, and so on. The convenience fee can either be absorbed by the business raising the invoice, or they can let their customers pay this fee during the checkout as they pay for the invoice (enable this option by going to your invoicing permissions under the settings page). The fee can vary depending upon the payment method being used to pay for the invoice raised. Note that international cards can cost additional charges beyond the convenience fee mentioned here.",
    "featureContent": [
      "2.90%",
      "2.90%",
      "2.90%"
    ]
  },
  {
    "featureTitle": "Package Invoicing - Manual",
    "featureDescription": "Send invoices for tutoring packages",
    "infoTooltip": "Package invoicing allows you to club together several hours of service into a single package per family, so you can invoice for multiple services, multiple students, and multiple tutors with varying rates all at the same time with a single invoice. You can also add discounts, taxes and additional charges on top of this invoice, and split it into multiple transactions if required.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Single Charge - Manual",
    "featureDescription": "Send invoices for one-time charges",
    "infoTooltip": "Single charge is intended for generating an invoice for a one-time payment. It allows you to add a description and a single amount that you want to generate an invoice for, and then send across that charge to the family to be paid via their client portal. Add discounts, taxes and additional charges on top of the base charge, and split the payment into multiple transactions if required.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Periodic Invoicing Automation",
    "featureDescription": "Send invoices automatically at a set frequency - weekly, bi-weekly, monthly, quarterly, or annually",
    "infoTooltip": "Schedule recurring invoices for services billed on a regular basis. Set custom intervals (e.g., weekly, monthly) and let the system handle reminders and invoicing automatically.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Post-session Automation",
    "featureDescription": "Auto-generate invoices after sessions",
    "infoTooltip": "Automatically create invoices once a session or service is completed. Ideal for on-demand or pay-per-session models, ensuring accurate and timely billing.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Pre-session Automation",
    "featureDescription": "Invoice before sessions begin",
    "infoTooltip": "Generate and send invoices prior to service delivery. Useful for ensuring advance payments or securing a commitment from clients.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Refund Requests",
    "featureDescription": "Manage refund claims efficiently",
    "infoTooltip": "Track and process refund requests from clients with ease. Custom workflows ensure approvals, deductions, and status updates are handled systematically.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Service Credits",
    "featureDescription": "Manage prepaid service credits",
    "infoTooltip": "Credit balance can be added in two forms - dollar amount (e.g. giving $300 credit per referral that your customer brings you) or service hours (e.g. giving 2 hours of free SAT prep). Credit balance is useful for incentivizing your customers to bring more referrals for your business and also avoid revenue loss from refunds due to subjective dissatisfaction towards a service.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Referral Credits",
    "featureDescription": "Reward clients for referrals",
    "infoTooltip": "Credit balance can be added in two forms - dollar amount (e.g. giving $300 credit per referral that your customer brings you) or service hours (e.g. giving 2 hours of free SAT prep). Credit balance is useful for incentivizing your customers to bring more referrals for your business and also avoid revenue loss from refunds due to subjective dissatisfaction towards a service.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Family settings",
    "featureDescription": "Manage family-based invoicing",
    "infoTooltip": "Family settings allow you to set certain defaults for each family, thus making each invoicing cycle unique as per your clients' satisfaction, helping you close them faster",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Advanced Filters",
    "featureDescription": "Find invoices with precision",
    "infoTooltip": "Advanced filters allow you to find and sort invoices based on the following factors: Invoice Type, Invoice Status, Tutors, Parents, Students, Services, and Amount (range). This way you can pin down exactly the invoices you are looking for without any hassles!",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Generate in Bulk",
    "featureDescription": "Create multiple invoices at once",
    "infoTooltip": "Save time by generating invoices in bulk for multiple clients or services. Ideal for batch processing recurring sessions or packages.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Bulk Edits",
    "featureDescription": "Edit multiple invoices simultaneously",
    "infoTooltip": "Make changes to multiple invoices in one go. Update dates, amounts, or client details to simplify corrections and adjustments.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Export Data",
    "featureDescription": "Export all your invoicing data in a spreadsheet",
    "infoTooltip": "Download invoice data in a spreadsheet for record-keeping, audits, or reporting purposes.",
    "featureContent": [
      "not_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Reports & Analytics",
    "featureDescription": "Track invoicing performance",
    "infoTooltip": "Access detailed analytics and reports on invoicing trends, payment collection, and client behavior to improve financial forecasting.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Revenue Trends",
    "featureDescription": "Monitor your revenue growth",
    "infoTooltip": "Visualize revenue patterns over time with graphs and charts. Identify high-performing periods and services.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Invoice Splitting",
    "featureDescription": "Split invoices into multiple transactions",
    "infoTooltip": "Divide a single invoice across multiple dates and payment methods. Perfect for shared services or family accounts.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Discounts",
    "featureDescription": "Apply discounts to invoices",
    "infoTooltip": "Add promotional or client-specific discounts with clear breakdowns. Supports both percentage and flat-rate discounts.",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Additional Charges",
    "featureDescription": "Include extra charges easily",
    "infoTooltip": "Add supplementary fees (e.g., late fees, material costs) to invoices without disrupting the overall structure.",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Adding Taxes",
    "featureDescription": "Ensure tax compliance",
    "infoTooltip": "Add custom tax rates for specific services. Applicable for both automated and manual invoicing. Simply add a description and a percentage value to be added to the base charges of your invoice.",
    "featureContent": [
      "not_allowed",
      "Limited",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Payment Links",
    "featureDescription": "Generate a payment link automatically or add your own",
    "infoTooltip": "Generate and share payment links for each invoice. Clients can pay online with just a click, ensuring convenience.",
    "featureContent": [
      "Mandatory",
      "Optional",
      "Optional"
    ]
  },
  {
    "featureTitle": "Auto-deduct",
    "featureDescription": "Automatically deduct payments from saved payment methods",
    "infoTooltip": "Enable auto-deduction for recurring services by saving client payment details securely. Ensures timely payments without manual follow-ups. Clients are required to provide consent for this when adding their card details.",
    "featureContent": [
      "Mandatory",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Remarks & Notes",
    "featureDescription": "Comment on your invoices",
    "infoTooltip": "Include remarks or special instructions for clients directly on the invoice. Useful for adding context or clarifying charges.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  }
]


const WL_DATA = [
  {
    "featureTitle": "Website",
    "featureDescription": "Add your organization's website link",
    "infoTooltip": "The provided URL will be displayed to users, giving them a direct link to your official website. Ensure the link is up-to-date and leads to a secure page.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Brand Logo",
    "featureDescription": "Upload your primary logo",
    "infoTooltip": "This logo will represent your brand across the platform. It is displayed prominently on sign-up forms and dashboards to ensure consistent branding.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Logo (Header) - White",
    "featureDescription": "Upload a white version of your logo",
    "infoTooltip": "The white logo is used on darker backgrounds for better visibility. This ensures your branding remains clear and professional regardless of the theme.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Favicon",
    "featureDescription": "Add a favicon for your platform",
    "infoTooltip": "A favicon is a small icon displayed on browser tabs, bookmarks, and address bars. Use a recognizable image to enhance brand recall.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Support Email",
    "featureDescription": "Add a support email address",
    "infoTooltip": "This email will be visible to users for assistance. Ensure it is monitored regularly to address user queries and support requests efficiently.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Color Theme",
    "featureDescription": "Choose a color theme for your platform",
    "infoTooltip": "Customize the header, buttons, and other interactive elements by selecting colors that align with your brand identity. Use HEX codes for precise color selection.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Preview Signup",
    "featureDescription": "Preview the signup flow for clients",
    "infoTooltip": "Use the preview option to see how the chosen color theme and selected form fields appear on the platform before applying them. Adjust if needed to maintain readability and aesthetics.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Custom Sub-Domain",
    "featureDescription": "Create your own sub-domain",
    "infoTooltip": "Create your own sub-domain by choosing your brand's unique name and adding it for your platform. The format of the sub-domain is \"brandname.evallo.org\".",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Signup Form Customization",
    "featureDescription": "Customize your user signup form",
    "infoTooltip": "Modify the fields visible during the signup process. Add or remove fields based on the information you require from students or parents.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Profile Fields",
    "featureDescription": "Collect basic user information",
    "infoTooltip": "Include mandatory fields such as first name, last name, and role (e.g., parent or student). This ensures you have essential details for account creation.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Additional Sections",
    "featureDescription": "Add more sections to the signup form",
    "infoTooltip": "Create additional sections for collecting specific information, such as educational background, goals, or preferences.",
    "featureContent": [
      "not_allowed",
      "not_allowed",
      "_allowed"
    ]
  }
]

const USERS_DATA = [
  {
    "featureTitle": "Team size",
    "featureDescription": "Define the number of team members allowed",
    "infoTooltip": "The maximum number of internal users (e.g., tutors, admins) who can access the platform simultaneously. Team size limits depend on the selected subscription plan. Upgrade plans to add more members.",
    "featureContent": [
      "1",
      "{plan specific}",
      "{plan specific}"
    ]
  },
  {
    "featureTitle": "Student Profile",
    "featureDescription": "Manage detailed student profiles",
    "infoTooltip": "Track essential student information, including academic progress, goals, and activity history. Use this data to personalize learning experiences and identify focus areas.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Tutor Profile",
    "featureDescription": "Showcase tutor expertise",
    "infoTooltip": "Showcase your tutors through shareable profiles that include their expertise, experience, qualifications, video highlight, reviews and more!",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Parent Profile",
    "featureDescription": "Record parent or guardian information",
    "infoTooltip": "Collect key details about parents or guardians, including their contact information and relationship to the student. Useful for communication and billing purposes.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Dashboards",
    "featureDescription": "Provide personalized dashboards",
    "infoTooltip": "Each user gets a role-specific dashboard to manage their tasks. Students can view assignments and progress, tutors can track performance, and admins can manage overall operations.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Services",
    "featureDescription": "Define the services offered by your business",
    "infoTooltip": "List and manage the types of services available, such as tutoring subjects, session durations, or packages. Helps clients choose the right service during onboarding.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Referral Codes",
    "featureDescription": "Incentivize referrals with unique codes",
    "infoTooltip": "Generate and distribute referral codes to your users. Track referrals and reward clients for bringing in new users, boosting growth through word-of-mouth marketing.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Groups",
    "featureDescription": "Organize users into manageable groups",
    "infoTooltip": "Club students into various groups and name them as per your choice. Use groups to assign tasks, track performance, or communicate efficiently.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Locations",
    "featureDescription": "Manage business locations",
    "infoTooltip": "Add and organize the physical locations where your services are offered. Useful for multi-branch businesses or centers to manage location-specific data and users.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "Bulk User Management",
    "featureDescription": "Add or update multiple users at once",
    "infoTooltip": "Use CSV uploads or templates to onboard or update user data in bulk. Saves time and reduces manual input errors.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  },
  {
    "featureTitle": "User Invitations",
    "featureDescription": "Add new users via email invites",
    "infoTooltip": "Send email invitations to onboard new users. Include role assignments and permissions during the invitation process to streamline setup.",
    "featureContent": [
      "_allowed",
      "_allowed",
      "_allowed"
    ]
  }
]


const TIER_SIZEMAPPING = {
		I0 : "1",
		B0 : "2-5",
		B1 : "6-10",
		B2 : "11-20",
		B3 : "21-50",
		B4 : "50+"
}
function getModifiedUserData(selectedTier){
	let tempUsersData = structuredClone(USERS_DATA)
	tempUsersData[0].featureContent[0] = TIER_SIZEMAPPING[selectedTier]
	tempUsersData[0].featureContent[1] = TIER_SIZEMAPPING[selectedTier]
	tempUsersData[0].featureContent[2] = TIER_SIZEMAPPING[selectedTier]
	return tempUsersData
}

export {
	getModifiedUserData,
	CALENDAR_DATA,
	ASN_DATA,
	INV_DATA,
	WL_DATA,
	USERS_DATA
}
