export default function ExplorerIcon(props){
	return(
		<svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width="44" height="43" rx="6" fill="#EEF6FF"/>
			<g filter="url(#filter0_f_44000_27404)">
				<path d="M19.2955 15.0003C20.4988 12.7599 21.1005 11.6396 22 11.6396C22.8994 11.6396 23.5012 12.7599 24.7044 15.0003L25.0159 15.58C25.3577 16.2166 25.5287 16.535 25.7952 16.745C26.0618 16.955 26.3939 17.033 27.058 17.1889L27.6624 17.3309C29.9992 17.8797 31.1675 18.154 31.4454 19.0818C31.7234 20.0095 30.9269 20.9762 29.334 22.9097L28.9217 23.4098C28.4691 23.9592 28.2428 24.2339 28.141 24.5739C28.0391 24.9137 28.0733 25.2803 28.1417 26.0133L28.2041 26.6806C28.445 29.2602 28.5652 30.5501 27.8375 31.1234C27.1098 31.6969 26.016 31.1741 23.8282 30.1285L23.2622 29.8579C22.6403 29.561 22.3294 29.4123 22 29.4123C21.6705 29.4123 21.3597 29.561 20.7378 29.8579L20.1718 30.1285C17.984 31.1741 16.8901 31.6969 16.1624 31.1234C15.4347 30.5501 15.5551 29.2602 15.7959 26.6806L15.8582 26.0133C15.9267 25.2803 15.9609 24.9137 15.8591 24.5739C15.7572 24.2339 15.5309 23.9592 15.0782 23.4098L14.6661 22.9097C13.0731 20.9762 12.2766 20.0095 12.5546 19.0818C12.8325 18.154 14.0009 17.8797 16.3376 17.3309L16.9421 17.1889C17.6061 17.033 17.9381 16.955 18.2047 16.745C18.4713 16.535 18.6423 16.2166 18.9841 15.58L19.2955 15.0003Z" fill="#0D96F2"/>
			</g>
			<path d="M19.2955 15.0003C20.4988 12.7599 21.1005 11.6396 22 11.6396C22.8994 11.6396 23.5012 12.7599 24.7044 15.0003L25.0159 15.58C25.3577 16.2166 25.5287 16.535 25.7952 16.745C26.0618 16.955 26.3939 17.033 27.058 17.1889L27.6624 17.3309C29.9992 17.8797 31.1675 18.154 31.4454 19.0818C31.7234 20.0095 30.9269 20.9762 29.334 22.9097L28.9217 23.4098C28.4691 23.9592 28.2428 24.2339 28.141 24.5739C28.0391 24.9137 28.0733 25.2803 28.1417 26.0133L28.2041 26.6806C28.445 29.2602 28.5652 30.5501 27.8375 31.1234C27.1098 31.6969 26.016 31.1741 23.8282 30.1285L23.2622 29.8579C22.6403 29.561 22.3294 29.4123 22 29.4123C21.6705 29.4123 21.3597 29.561 20.7378 29.8579L20.1718 30.1285C17.984 31.1741 16.8901 31.6969 16.1624 31.1234C15.4347 30.5501 15.5551 29.2602 15.7959 26.6806L15.8582 26.0133C15.9267 25.2803 15.9609 24.9137 15.8591 24.5739C15.7572 24.2339 15.5309 23.9592 15.0782 23.4098L14.6661 22.9097C13.0731 20.9762 12.2766 20.0095 12.5546 19.0818C12.8325 18.154 14.0009 17.8797 16.3376 17.3309L16.9421 17.1889C17.6061 17.033 17.9381 16.955 18.2047 16.745C18.4713 16.535 18.6423 16.2166 18.9841 15.58L19.2955 15.0003Z" fill="#0D96F2"/>
			<defs>
				<filter id="filter0_f_44000_27404" x="6.1" y="5.23965" width="31.8" height="32.5202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="3.2" result="effect1_foregroundBlur_44000_27404"/>
				</filter>
			</defs>
		</svg>

	)
}
