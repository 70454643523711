export default function CrewMbIcon(props){
	return(
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_43361_46119)">
				<g filter="url(#filter0_i_43361_46119)">
					<rect x="0.166992" width="16" height="16" rx="2.7234" fill="white"/>
				</g>
				<g filter="url(#filter1_f_43361_46119)">
					<path d="M8.32414 9.2035H5.84945C5.66917 9.2035 5.55556 9.00624 5.64381 8.84646L8.58108 3.52735C8.70666 3.29993 9.04869 3.41109 9.0212 3.67037L8.68966 6.79659H11.1643C11.3446 6.79659 11.4582 6.99385 11.37 7.15363L8.43271 12.4727C8.30713 12.7001 7.9651 12.589 7.9926 12.3297L8.32414 9.2035Z" fill="#4092E6"/>
				</g>
				<path d="M8.32414 9.2035H5.84945C5.66917 9.2035 5.55556 9.00624 5.64381 8.84646L8.58108 3.52735C8.70666 3.29993 9.04869 3.41109 9.0212 3.67037L8.68966 6.79659H11.1643C11.3446 6.79659 11.4582 6.99385 11.37 7.15363L8.43271 12.4727C8.30713 12.7001 7.9651 12.589 7.9926 12.3297L8.32414 9.2035Z" fill="#4092E6"/>
			</g>
			<defs>
				<filter id="filter0_i_43361_46119" x="0.166992" y="0" width="17.3617" height="18.2128" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dx="1.3617" dy="2.38298"/>
					<feGaussianBlur stdDeviation="1.10638"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43361_46119"/>
				</filter>
				<filter id="filter1_f_43361_46119" x="3.43456" y="1.22557" width="10.1446" height="13.5489" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="1.08936" result="effect1_foregroundBlur_43361_46119"/>
				</filter>
				<clipPath id="clip0_43361_46119">
					<rect width="16" height="16" fill="white" transform="translate(0.166992)"/>
				</clipPath>
			</defs>
		</svg>

	)
}
